import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import theme from 'core/theme/theme';
import { CircularProgress } from '@mui/material';

type TType = "text" | "number";

interface Props {
  onChange: (value: string) => void;
  searchPlaceHolder?: string;
  querry: string;
  querrySearching?: boolean;
  type: TType;
}

function Search({ onChange, searchPlaceHolder, querrySearching, querry, type }: Readonly<Props>) {
  const [inputValue, setInputValue] = useState(querry);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setInputValue(querry);
  }, [querry]);

  const handleChange = (value: string) => {
    if(type === "text") {
      setInputValue(value);
    }
    else {
      setInputValue(value.replace(/[^0-9]/g, ''));
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onChange(value);
    }, 500);
  };

  const clearSearch = () => {
    setInputValue('');
    onChange('');
  };

  const handlePaste = (event: any) => {
    const pastedValue = event.clipboardData.getData('Text');
    const trimmedValue = pastedValue.replace(/\s+$/, '').replace(/\s+/g, ' ');
    handleChange(trimmedValue);
    event.preventDefault();
};

  return (
    <SearchContainer>
      <SearchIcon
        sx={{
          color: theme.COLORS.YELLOW2,
          width: 20,
          height: 20,
        }}
      />
      <SearchInput
        type="text"
        value={inputValue}
        onPaste={handlePaste}
        onChange={event => handleChange(event.target.value)}
        autoComplete="off"
        placeholder={searchPlaceHolder || "Pesquisar..."}
      />

      {querrySearching && querry &&
        <div>
          <CircularProgress size={10} />
        </div>
      }

      {querry &&
        <CloseIcon
          onClick={() => clearSearch()}
          sx={{
            color: theme.COLORS.YELLOW2,
            width: 20,
            height: 20,
            cursor: 'pointer',
            ":hover": {
              color: theme.COLORS.YELLOW
            }
          }}
        />
      }
    </SearchContainer>
  );
}

export default Search

const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.GRAY7};
  border: 2px solid ${({ theme }) => theme.COLORS.GRAY6};
  border-radius: 5px;
  height: min-content;
  min-width: 190px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${({ theme }) => theme.COLORS.GRAY6};
    border: 2px solid ${({ theme }) => theme.COLORS.YELLOW2};
  }
`

const SearchInput = styled.input`
  width: 100%;
  border-color: transparent;
  background-color: transparent;
  outline-style: none;
`;