import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TProductCharacteristics } from "core/models/product"
import theme from "core/theme/theme";
import { InfoColumn, InfoValue } from "../styles";
import { InfoKey } from "app/components/styles";
import Spinner from "app/components/spinner/spinner";

interface ICaracteristicsProps {
    caracteritics: TProductCharacteristics[];
}

const CaracteristicsInfo = (props: ICaracteristicsProps) => {
    const { caracteritics } = props;

    return (
        <>
            <TableContainer sx={{ height: 205, position: 'relative', border: "2px solid", borderColor: theme.COLORS.GRAY6, borderRadius: "5px" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow style={{ background: theme.COLORS.GRAY3 }}>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left">Qtd</TableCell>
                            <TableCell align="left">Descrição</TableCell>
                            <TableCell align="left">Variante</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {caracteritics.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {row.characteristics.name}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {row.amount}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {row.description}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {row.characteristics.description}
                                </TableCell>
                            </TableRow>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CaracteristicsInfo