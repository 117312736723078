import { Add } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import DefaultFilter, { ISelectItem } from 'app/components/filter/defaultFilter'
import DefaultMenu, { IMenuItemProps } from 'app/components/menu/DefaultMenu'
import Search from 'app/components/search/Search'
import Spinner from 'app/components/spinner/spinner'
import { PageContentContainer } from 'app/components/styles'
import DataTable from 'app/components/table/table/table'
import TableHeader from 'app/components/table/tableHeader/TableHeader'
import { ContentBody } from 'app/styles'
import { ITableHeadCell, Order } from 'core/models/table'
import theme from 'core/theme/theme'
import { useEffect, useState } from 'react'
import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DataTablePagination from 'app/components/table/pagination/pagination'
import { fecthCollection } from 'core/querryes/collection/collectionQuerry'
import { useQuery } from '@tanstack/react-query'
import { TCollectionBody, TCollectionFilterRequest } from 'core/models/collection'
import EditCollectionModal from '../editers/editCollection'
import NewDefaultFilter from 'app/components/filter/newDefaultFilter'
import { set } from 'date-fns'
import { CollectionService } from 'core/api/collection/collectionService'

const head: ITableHeadCell[] = [
    { name: 'name', label: 'Nome', align: 'left' },
    { name: 'description', label: 'Descrição', align: 'left' },
    { name: 'createdAt', label: 'Criado em', align: 'left' },
    { name: 'active', label: 'Ativo', align: 'left' },
    { name: 'actions2', label: 'Opções', align: 'left' }
]

const filterItems: ISelectItem[] = [
    { name: 'Nome', value: 'name', type: 'texto' }
]

function Collection() {
    const navigate = useNavigate()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [filterModal, setFilterModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [idCollection, setIdCollection] = useState<string | undefined>()
    const [collectionFilters, setCollectionFilters] =
        useState<TCollectionFilterRequest>({
            name: ''
        })
    const [isOpen, setIsOpen] = useState(false)
    const [tempCollection, setTempCollection] = useState<TCollectionBody | undefined>()

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleAccessRowById = (id: string) => {
        setIdCollection(id)
        setTempCollection(data?.content.find((collection: any) => collection.id === id))
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const name = searchParams.get('name') || ''

    const { data, isLoading, isSuccess, refetch } = useQuery({
        queryKey: [
            'collection',
            page,
            rowsPerPage,
            orderBy,
            order,
            name
        ],
        queryFn: () =>
            fecthCollection(
                page,
                rowsPerPage,
                orderBy,
                order,
                name
            ),
        staleTime: Infinity
    })

    const menuItems: IMenuItemProps[] = [
        {
            function: () => {
                setIsOpen(true)
                handleCloseMenu()
            },
            label: 'Editar coleção'
        },
        {
            function: async () => {
                await CollectionService.activeInativeCollection(
                    String(tempCollection?.id)
                ).then((resp) => {
                    refetch()
                    handleCloseMenu()
                })
                refetch()
                handleCloseMenu()
            },
            label: tempCollection?.active ? 'Inativar coleção' : 'Ativar coleção'
        }
    ]

    const handleNameChange = (value: string | undefined) => {
        const newParams = new URLSearchParams(searchParams)
        if (value) {
            newParams.set('name', value ?? '')
        } else {
            newParams.delete('name')
        }
        setSearchParams(newParams)
    }


    //teste
    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements)
        }
    }, [isSuccess, data])

    const selectedCollection = data?.content.find(
        (collection: any) => collection.id === idCollection
    )

    return (
        <PageContentContainer>
            <TableHeader
                filterBtn
                filterBtnAction={() => setFilterModal(true)}
                mainActionFunction={() => navigate('/registrarColecao')}
                mainActionLabel="Cadastrar coleção"
                mainIcon={<Add sx={{ color: theme.COLORS.YELLOW2 }} />}
                extraComponents={
                    <Search
                        searchPlaceHolder="Nome da coleção..."
                        type="text"
                        querrySearching={isLoading}
                        querry={name}
                        onChange={handleNameChange}
                    />
                }
            />
            <ContentBody>
                <DataTable
                    head={head}
                    data={data?.content}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    isLoading={isLoading}
                    accessRowById={handleAccessRowById}
                    menu={
                        <Tooltip title="Opções">
                            <IconButton onClick={handleClickMenu}>
                                <MoreHorizRounded />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <DefaultMenu
                    anchor={anchorEl}
                    menuItems={menuItems}
                    onClose={handleCloseMenu}
                    status={open}
                />
                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
                <NewDefaultFilter
                    isOpen={filterModal}
                    items={filterItems}
                    onClose={() => setFilterModal(false)}
                    onOpen={() => setFilterModal(true)}
                    title="Filtrar Coleções"
                    changePage={setPage}
                />
                {selectedCollection && isOpen && (
                    <EditCollectionModal
                        isOpen={isOpen}
                        refetch={refetch}
                        setIsOpen={setIsOpen}
                        onClose={() => setIsOpen(false)}
                        onOpen={() => setIsOpen(true)}
                        collection={selectedCollection}
                    />
                )}
            </ContentBody>
        </PageContentContainer>
    )
}

export default Collection
