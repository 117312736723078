
import {
    Box,
    Button,
    CircularProgress,
    MenuItem,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Validations } from "core/utils/validations";
import { useCallback, useEffect, useState } from "react";
import { getIn, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { LoginContainer, LoginContentBox, LoginLogo } from "../styles";
import packageJson from "../../../../../package.json";  // Importa o package.json
import { getFilial } from "core/api/filial/filialService";
import { Notification } from "app/components/toastNotification/toastNotification";

type TSelectFilial = {
    filial: string;
}

const SelectFilial = () => {
    const currentTheme = useTheme();
    const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [filialOptions, setFilialOptions] = useState<any[]>([]);
    const [checkingOptions, setCheckingOptions] = useState(false);

    const initialValues: TSelectFilial = {
        filial: "",
    };

    useEffect(() => {
        const fetchFilial = async () => {
            setCheckingOptions(true);
            try {
                const response = await getFilial();
                if (response) {
                    setFilialOptions(response);
                }
            }
            catch (error) {
                console.error("Erro ao buscar dados: ", error);
            }
            finally {
                setCheckingOptions(false);
            }
        };
        fetchFilial();
    }, [])



    const handleSelectFilial = useCallback((values: TSelectFilial) => {
        if (values.filial === "" || values.filial === undefined) {
            Notification("Selecione sua filial", "error");
        }
        localStorage.setItem("filial", values.filial);
        navigate("/login");
    },
        []
    );

    const formatFilial = (filialName: string) => {
        switch (filialName) {
            case 'campina-grande':
                return 'Campina Grande';
            case 'joao-pessoa':
                return 'João Pessoa';
            case 'guarabira':
                return 'Guarabira';
            default:
                return filialName;
        }
    };

    const formik = useFormik({
        validateOnChange: false,
        initialValues,
        validationSchema: Validations.filialSchema,
        onSubmit: handleSelectFilial,
    });

    return (
        <LoginContainer
            sx={{ display: "flex" }}
            $small={isSmallScreen}
            maxWidth={isSmallScreen ? "sm" : "md"}
            disableGutters
        >
            <LoginLogo $small={isSmallScreen}></LoginLogo>

            <LoginContentBox onSubmit={formik.handleSubmit} $small={isSmallScreen}>
                <Typography
                    sx={{
                        fontSize: "1.1pc",
                        fontWeight: "bold",
                        marginTop: isSmallScreen ? 0 : "-10px",
                    }}
                >
                    SELECIONE SUA FILIAL
                </Typography>
                <Typography
                    sx={{
                        fontSize: "0.9pc",
                        marginTop: isSmallScreen ? 0 : "-5x",
                    }}
                >
                    Após a seleção da sua filial você será redirecionado para a página de login.
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>

                    <TextField
                        value={formik.values.filial}
                        onChange={e => {
                            formik.setFieldValue('filial', e.target.value);
                        }}
                        id="outlined-select-state"
                        margin="none"
                        select
                        label="Filial"
                        size="small"
                        style={{ width: "100%" }}
                        name="filial"
                        error={Boolean(getIn(formik.errors, "filial"))}
                        helperText={getIn(formik.errors, "filial")}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 100,
                                    },
                                },
                            },
                            sx: {
                                textAlign: 'left',
                                '.MuiSelect-select': {
                                    textAlign: 'left',
                                },
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                margin: '1px 10px -5px',
                            },
                        }}
                    >
                        {checkingOptions ? (
                            <MenuItem disabled sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <CircularProgress color="inherit" size={20} />
                            </MenuItem>
                        ) : (
                            filialOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {formatFilial(String(option))}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </Box>
                <Button
                    type={"submit"}
                    sx={{
                        fontWeight: "bold",
                        borderRadius: 1,
                        position: "relative",
                        height: "45px",
                    }}
                    fullWidth
                    variant="contained"
                >
                    <Typography>PRÓXIMO</Typography>
                </Button>
                <Typography sx={{ fontSize: "0.8pc", marginTop: 6 }}>V {packageJson.version}</Typography>
            </LoginContentBox>
        </LoginContainer>
    );
};

export default SelectFilial;
