import { Box, Button, Paper } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)<{ $small?: boolean }>`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: ${({ $small }) => ($small ? 'scroll' : 'hidden')};
    overflow-x: hidden;
    max-height: ${({ $small }) => ($small ? '80vh' : 'none')};
    max-width: ${({ $small }) => ($small ? '360px' : 'none')};
`

export const ButtonsContainer = styled(Box)<{ $small?: boolean }>`
    display: flex;
    flex-direction: ${({ $small }) => ($small ? 'row' : 'column')};
    align-items: flex-end;
    border-radius: 3px;
    margin: auto;
`

export const SecondContainer = styled(Box)<{ $small?: boolean }>`
    display: flex;
    flex-direction: ${({ $small }) => ($small ? 'column' : 'row')};
`

export const StyledPaper = styled(Paper)<{ $small?: boolean }>`
    display: flex;
    position: absolute !important;
    height: 400px;
    align-items: center;
    gap: -1px;
    border-radius: 3px;
    border-left: 10px solid #1f299c;
    background: #f1f1f1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 18px 1px rgba(0, 0, 0, 0.5);
`

export const StyledDivPrimaryCalendar = styled.div<{ $small?: boolean }>`
    display: flex;
    width: ${({ $small }) => ($small ? '100%' : '714px')};
    height: ${({ $small }) => ($small ? '' : '360px')};
    flex-direction: column;
    align-items: ${({ $small }) => ($small ? 'center' : 'flex-start')};
    justify-content: ${({ $small }) => ($small ? 'center' : 'flex-start')};
`
export const StyledDivSelectCalendar = styled.div<{ $small?: boolean }>`
    display: flex;
    width: 162px;
    height: 99%;
    padding: 2% 7px 7px 7px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    background-color: #f3f3f4;
`

export const StyledDivTextCalendar = styled.div<{ $small?: boolean }>`
    display: flex;
    width: ${({ $small }) => ($small ? '100%' : '714px')};
    height: ${({ $small }) => ($small ? '61px' : '61px')};
    flex-direction: ${({ $small }) => ($small ? 'column' : 'row')};
    align-items: ${({ $small }) => ($small ? 'center' : 'flex-start')};
    justify-content: ${({ $small }) => ($small ? 'center' : 'flex-start')};
    gap: 26%;
    flex-shrink: 0;
`
export const StyledDivCalendar = styled.div<{ $small?: boolean }>`
    display: flex;
    width: ${({ $small }) => ($small ? '379px' : '714px')};
    height: ${({ $small }) => ($small ? '714px' : '379px')};
    justify-content: center;
    align-items: ${({ $small }) => ($small ? 'center' : 'flex-start')};
    gap: 13px;
    flex-shrink: 0;
    flex-direction: ${({ $small }) => ($small ? 'column' : 'row')};
    .MuiDateCalendar-viewTransitionContainer {
        height: 15rem;
    }
`
export const StyledButton = styled(Button)`
    && {
        font-size: 16px;
        color: #515050;

        &:hover {
            background-color: rgb(243, 243, 244);
        }

        :focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
        }
    }
`
export const StyledButtonDates = styled(Button)`
    && {
        display: initial;
        text-align: left;
        width: 100%;
        color: #000;
        font-size: 12px;
        text-transform: none;
    }
`
