import {
    FormControl,
    FormHelperText,
    InputLabel,
    InputProps,
    MenuItem,
    OutlinedInput,
    Select
} from '@mui/material'
import { getIn } from 'formik'
import { useCallback, useEffect, useState } from 'react'

interface ICampoTesteProps extends InputProps {
    lenght?: number // tamanho máximo do campo
    formik?: any // acesso a erros e função setFieldValue
    name: string // nome do campo para acessar o formik
    label: string // label do campo
    isLoading?: boolean // se o campo está carregando
    formater?: (value: any) => string | number // formatar o valor da forma desejada
    valueWithoutFormik?: string // valor caso não precise do formik
    changeValueWithoutFormik?: (value: string) => void
    customOnBlur?: (e: any) => void // caso queira chamar uma func especifica
    selectMode?: boolean
    options?: { value: string; label: string }[]
}

export default function CustomField({
    formater,
    label,
    name,
    isLoading,
    formik,
    valueWithoutFormik,
    changeValueWithoutFormik,
    customOnBlur,
    lenght,
    selectMode,
    options,
    ...htmlInputProps
}: Readonly<ICampoTesteProps>) {

    const [localValue, setLocalValue] = useState(
        formik ? getIn(formik.values, name) : valueWithoutFormik
    );

    // devolve o valor do input dependendo se é estado local ou formik, e aplica formatação
    const formatedValue = useCallback(() => {
        if (formik && formik.values) {
            return formater
                ? formater(getIn(formik.values, name))
                : getIn(formik.values, name)
        } else {
            return formater ? formater(valueWithoutFormik) : valueWithoutFormik
        }
    }, [valueWithoutFormik, formik, name, formater])

    // Handle para alteração do valor
    const handleChangeInput = (e: any) => {
        const newValue = e.target.value;
        setLocalValue(newValue);

        if (formik) {
            formik.setFieldValue(name, newValue);
        } else if (changeValueWithoutFormik) {
            changeValueWithoutFormik(newValue);
        }
    };

    // Atualizar estado local quando o valor do Formik mudar
    useEffect(() => {
        const formikValue = formik ? getIn(formik.values, name) : valueWithoutFormik;
        setLocalValue(formater ? formater(formikValue) : formikValue);
    }, [formik, name, valueWithoutFormik, formater]);

    return (
        <FormControl size="small">
            {/* label */}
            <InputLabel
                htmlFor="outlined-adornment-amount"
                size="small"
                error={formik && !!getIn(formik.errors, name)}
            >
                {label}
            </InputLabel>

            {selectMode && options ? (
                // select input
                <Select
                    name={name}
                    label={label}
                    disabled={isLoading}
                    size="small"
                    error={formik && !!getIn(formik.errors, name)}
                    style={{
                        backgroundColor: !isLoading ? '#fff' : '#eaeaea'
                    }}
                    value={
                        formik ? getIn(formik.values, name) : valueWithoutFormik
                    }
                    onChange={(e) =>
                        formik
                            ? formik.setFieldValue(name, e.target.value)
                            : changeValueWithoutFormik?.(e.target.value)
                    }
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                // text input
                <OutlinedInput
                    id="outlined-adornment-amount"
                    size="small"
                    disabled={isLoading}
                    name={name}
                    label={label}
                    autoComplete="off"
                    value={localValue}
                    margin="none"
                    error={formik && !!getIn(formik.errors, name)}
                    onBlur={(e) => {
                        formik ? formik.validateField(name) : customOnBlur?.(e)
                    }}
                    onChange={handleChangeInput}

                    inputProps={{
                        maxLength: lenght
                    }}
                    style={{
                        backgroundColor: !isLoading ? '#fff' : '#eaeaea'
                    }}
                    {...htmlInputProps}
                />
            )}

            {/* se tiver erro mostra a mensagem */}
            {formik
                ? !!getIn(formik.errors, name) && (
                    <FormHelperText
                        sx={{ marginLeft: 1, marginBottom: 0 }}
                        error={!!getIn(formik.errors, name)}
                    >
                        {getIn(formik.errors, name)}
                    </FormHelperText>
                )
                : null}
        </FormControl>
    )
}
