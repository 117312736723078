import { Box } from '@mui/material'
import SellerSalesByMonth from './sellersSalesByMonth'
import ListSalesMonth from './listSalesMonth'
export default function Dashboard() {
    return (
        <Box
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            }}
        >
            <SellerSalesByMonth />
            <ListSalesMonth />
        </Box>
    )
}
