/* eslint-disable import/no-anonymous-default-export */

export default {
  COLORS: {
    TEXT: "#969CB2",
    GRAY: "#999591",
    BLACK: "#100C08",
    GRAY_100: "#B8B8B8",
    ICON: "#666666",
    WHITE: "#FFFFFF",
    TITLE: "#363F5F",
    SHAPE: "#f4ede8",
    PRIMARY: "#1DC18B",
    GRAYHARD: "#666360",
    TEXTDARK: "#000000",
    WHITE_100: "#FFFFFF",

    RED: "#EB5757",
    ORANGE_300: "#fbb605",
    ORANGE: "#F2994A",
    YELLOW2: "#DBAB3A",
    YELLOW: "#F2C94C",
    GREEN4: "#83e509",
    GREEN3: "#90EE90",
    GREEN2: "#27AE60",
    GREEN1: "#219653",
    BLUE4: "#007991",
    BLUE3: "#00AECB",
    BLUE2: "#56CCF2",
    BLUE: "#88e3f7",
    GRAY7: "#F7F9FA",
    GRAY6: "#F2F2F2",
    GRAY5: "#E0E0E0",
    GRAY4: "#BDBDBD",
    GRAY3: "#828282",
    GRAY2: "#4F4F4F",
    GRAY1: "#333333",

    PINK: "#D71CDB",
    PURPLE2: "#BB6BD9",
    PURPLE1: "#9B51E0",
    PURPLE: "#9038FF",
    PURPLEDARK1: "#6E34B8",
    PURPLEDARK2: "#5B259F",
    PURPLEDARK3: "#2F1155",

    TONE: "#E6E3D6",
    AVATAR: "#E0E0E0",
    BORDER: "#DEDCD3",
    SPEACH: "#D5CFB9",
    SPEACH200: "#bab49f",
    SPEACH300: "#b7ad87",
    SPEACH400: "#aea997",
    SPEACH500: "#928b72",
    SPEACH600: "#888577",
    INFORMATIVO: "#F3B263",

    TYPOTIME: "#6E664E",
    TYPOGHOST: "#999999",
    TYPOBrand: "#55AB67",
    TYPOSOCIAL: "#5B75A6",
    TYPOPRIMARY: "#000000",
    TYPOSECONDARY: "#333333",

    TONEBG: "#E6E3D6",
    TONEYPE: "#6E664E",
    TONE1829: "#f3ebd9",
    TONESTO: "#e4daca",
    TONEPAINT: "#f2d9be",
    TONEGLIDDEN: "#e7e3d6",
    TONEOLYMPIC: "#ede3d6",
    TONEPANTONE: "#f0e4d7",
    TONECAPAROL: "#e4d8d0",
    TONEBRISTOL: "#ecddc9",
    TONEMATTNEWS: "#f2d9be",
    TONEHOMEBASE: "#f0e9d9",

    BACKGROUND: "#E5E5E5",
    BACKGROUNDDARK: "#312e38",
    BACKGROUNDLIGHT: "#FFFFFF",
    BACKGROUNDOFICIAL: "#F2EFE5",
    BACKGROUNDDEFAULT: "#F1EFE5",

    SECONDARY: "#FF872C",
    SECONDARY_LIGHT: "rgba(255, 135, 44, 0.3)",
    SECONDARY_LIGHT4: "rgba(255, 135, 44, 0.4)",
    SECONDARY_LIGHT2: "rgba(255, 135, 44, 0.2)",
    SECONDARY_LIGHT1: "rgba(255, 135, 44, 0.1)",

    SUCCESS: "#12A454",
    SUCCESS_LIGHT: "rgba(18, 164, 84, 0.5)",
    SUCCESS_LIGHT1: "rgba(18, 164, 84, 0.1)",
    SUCCESS_LIGHT2: "rgba(18, 164, 84, 0.2)",
    SUCCESS_LIGHT3: "rgba(18, 164, 84, 0.3)",
    SUCCESS_LIGHT4: "rgba(18, 164, 84, 0.4)",
    SUCCESS_LIGHT5: "rgba(18, 164, 84, 0.5)",
    SUCCESS_LIGHT6: "rgba(18, 164, 84, 0.6)",
    SUCCESS_LIGHT7: "rgba(18, 164, 84, 0.7)",
    SUCCESS_LIGHT8: "rgba(18, 164, 84, 0.8)",
    SUCCESS_LIGHT9: "rgba(18, 164, 84, 0.9)",

    ATTENTION: "#E83F5B",
    ATTENTION_LIGHT1: "rgba(232, 63, 91, 0.1)",
    ATTENTION_LIGHT2: "rgba(232, 63, 91, 0.2)",
    ATTENTION_LIGHT3: "rgba(232, 63, 91, 0.3)",
    ATTENTION_LIGHT4: "rgba(232, 63, 91, 0.4)",
    ATTENTION_LIGHT: "rgba(232, 63, 91, 0.5)",
    ATTENTION_LIGHT6: "rgba(232, 63, 91, 0.6)",
    ATTENTION_LIGHT7: "rgba(232, 63, 91, 0.7)",
    ATTENTION_LIGHT8: "rgba(232, 63, 91, 0.8)",
    ATTENTION_LIGHT9: "rgba(232, 63, 91, 0.9)",
  },

  FONTS_SIZE: {
    SM: "10px",
    MD: "12px",
    LG: "15px",
    XL: "18px",
    XX: "20px",
  },
};
