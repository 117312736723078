import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    CircularProgress
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { dashboardService } from 'core/api/dashboard/dashboardService'
import theme from 'core/theme/theme'
import {
    formatCurrencyBR,
    formatDateToSearch
} from 'core/utils/globalFunctions'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import styled from 'styled-components'
import DateRangeDisplay from 'app/components/dateRange/dateRangeDisplay/dateRangeDisplay'

export default function SellerSalesByMonth() {
    // Estado do intervalo de data
    const [initDate, setInitDate] = useState<Dayjs | null | undefined>(
        dayjs().startOf('month')
    )
    const [finalDate, setFinalDate] = useState<Dayjs | null | undefined>(
        dayjs().endOf('month')
    )

    const sellersDashboard = useQuery({
        queryKey: ['dashboard', initDate, finalDate],
        staleTime: Infinity,
        queryFn: () =>
            dashboardService.getCollaboratorSales(
                formatDateToSearch(initDate!),
                formatDateToSearch(finalDate!)
            )
    })

    // Normaliza nomes
    function normalizeSellerName(name: string): string {
        return name
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    return (
        <TableContainer sx={{ minWidth: 900 }} component={Paper}>
            <StyledBox>
                <StyledTypography>Período</StyledTypography>
                <DateRangeDisplay
                    typeOfDatePicker={'month'}
                    initialDate={initDate}
                    endDate={finalDate}
                    setInitialDate={setInitDate}
                    setFinalDate={setFinalDate}
                />
            </StyledBox>
            <StyledTable size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Vendedor</StyledTableCell>
                        <StyledTableCell>Dinheiro</StyledTableCell>
                        <StyledTableCell>Pix</StyledTableCell>
                        <StyledTableCell>Crédito</StyledTableCell>
                        <StyledTableCell>Débito</StyledTableCell>
                        <StyledTableCell>Consignadas</StyledTableCell>
                        <StyledTableCell>Promissórias</StyledTableCell>
                        <StyledTableCell>Total</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(() => {
                        if (sellersDashboard.isLoading) {
                            return (
                                <StyledTableRow>
                                    <TableCell colSpan={3}>
                                        <LoadingWrapper>
                                            <CircularProgress />
                                        </LoadingWrapper>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        }
                        if (sellersDashboard.data) {
                            return sellersDashboard.data.map((row) => (
                                <TableRow key={row.name_collaborator}>
                                    <StyledBodyTableCell>
                                        {normalizeSellerName(
                                            row.name_collaborator
                                        )}
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount_dinheiro
                                                )}
                                            </div>
                                            <div>
                                                Qtd: {row.total_vendas_dinheiro}
                                            </div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount_pix
                                                )}
                                            </div>
                                            <div>
                                                Qtd: {row.total_vendas_pix}
                                            </div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount_credito
                                                )}
                                            </div>
                                            <div>
                                                Qtd: {row.total_vendas_credito}
                                            </div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount_debito
                                                )}
                                            </div>
                                            <div>
                                                Qtd: {row.total_vendas_debito}
                                            </div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount_consignado
                                                )}
                                            </div>
                                            <div>
                                                Qtd:{' '}
                                                {row.total_vendas_consignado}
                                            </div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount_promissorias
                                                )}
                                            </div>
                                            <div>
                                                Qtd:{' '}
                                                {row.total_vendas_promissorias}
                                            </div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        <CellContent>
                                            <div>
                                                {formatCurrencyBR(
                                                    row.total_amount
                                                )}
                                            </div>
                                            <div>Qtd: {row.total_vendas}</div>
                                        </CellContent>
                                    </StyledBodyTableCell>
                                </TableRow>
                            ))
                        }
                        return (
                            <StyledTableRow>
                                <TableCell colSpan={3}>
                                    <NoDataMessage>
                                        Nada encontrado
                                    </NoDataMessage>
                                </TableCell>
                            </StyledTableRow>
                        )
                    })()}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

const StyledTable = styled(Table)`
    height: 100% !important;
    overflow: auto !important;
`

const StyledBox = styled(Box)`
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 0 0 0.5rem !important;
    border: solid 2px ${theme.COLORS.YELLOW2} !important;
`

const StyledTypography = styled.div`
    color: ${theme.COLORS.YELLOW2} !important;
    font-weight: bold !important;
    font-size: 15px !important;
    text-align: center !important;
`

const StyledTableCell = styled(TableCell)`
    background: ${theme.COLORS.YELLOW2} !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 15px !important;
`

const StyledBodyTableCell = styled(TableCell)`
    font-weight: bold !important;
    font-size: 13px !important;
`

const CellContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`

const StyledTableRow = styled(TableRow)`
    height: 100% !important;
`

const LoadingWrapper = styled.div`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 300px !important;
`

const NoDataMessage = styled.div`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 300px !important;
    font-size: 15px !important;
    font-weight: bold !important;
    color: ${theme.COLORS.YELLOW2} !important;
`
