import { Box, Typography } from "@mui/material";
import theme from "core/theme/theme";
import styled from "styled-components";

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 650px;
`;

export const PaymentMethodContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaymentOptionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  margin: 0.5rem;
   flex: 1;
`;

export const PaymentOption = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(200, 255, 200, 0.6);
  border-radius: 0.3rem;
  justify-content: center;
  padding: 0.5rem;
  flex: 1;
  border: 2px solid ${theme.COLORS.GREEN1};
  cursor: pointer;
`;

export const PaymentValue = styled(Typography)`
  font-size: 12px;
  min-width: 60px;
  font-weight: bold;
  color: ${theme.COLORS.GREEN1};
`;

export const PaymentsListContainer = styled(Box)`
  display: flex;
  flex: 3;
  min-height: 150px;
  max-height: 170px;
  overflow-y: auto;
  gap: 1rem;
  flex-direction: column;
  border-top: 2px solid ${theme.COLORS.GRAY5};
  border-bottom: 2px solid ${theme.COLORS.GRAY5};
  padding: 1rem;
`;

export const PaymentItem = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  background: ${theme.COLORS.GRAY6};
  border-radius: 0.4rem;
`;

export const PaymentItemDetails = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-around;
  align-items: center;
  width: 70%;
`;

export const PaymentItemActions = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 1%;
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.3rem;
`;

export const ActionButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 80%;
  gap: 2rem;
`;

export const LoadingContainer = styled(Box)`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const PaymentModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0rem 1rem 1rem 1rem;
`;

export const PaymentModalHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const PaymentModalFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`;
