import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField
} from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import GenericTextField from 'app/components/genericTextField/GenericTextField'
import {
    InfoCard,
    InfoCardContainer,
    InfoCardTitle
} from 'app/components/styles'
import { Notification } from 'app/components/toastNotification/toastNotification'
import { AxiosError } from 'axios'
import { CollaboratorService } from 'core/api/collaborator/collaboratorService'
import {
    TCollaboratorRegister,
    TCollaboratorRegisterValues
} from 'core/models/collaborator'
import {
    convertToBack,
    formatDate,
    formatDocument,
    formatarCEP,
    removeExtraSpaces,
    removeNonNumeric,
    sexoOptions,
    statesOpitions
} from 'core/utils/globalFunctions'
import { Dayjs } from 'dayjs'
import { getIn, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Validations } from 'core/utils/validations'
import { RegisterPage, RegisterPageContent, RegisterPageHeader } from './styles'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import Grid2 from '@mui/material/Unstable_Grid2'
import DataPicker from 'app/components/dataPicker/datePicker'
import theme from 'core/theme/theme'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function RegisterCollaborator() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isValidating, setValidating] = useState(false)



    dayjs.locale('pt-br')

    const initialValues: TCollaboratorRegisterValues = {
        name: '',
        rg: '',
        cpforcnpj: '',
        birthday: '',
        user: {
            password: '',
            confirmPassword: ''
        },
        addressDTO: {
            zipCode: '',
            uf: '',
            road: '',
            number: '',
            neighborhood: '',
            city: ''
        },
        contactDTO: {
            telephone: '',
            cellPhone: '',
            cellPhone2: '',
            email: ''
        },
        sexoID: ''
    }

    const validateInfos = (): boolean => {
        if (
            formik.values.user.password !== formik.values.user.confirmPassword
        ) {
            formik.setFieldError('user.password', 'Senhas não conferem')
            setIsLoading(false)
            return false
        }
        return true
    }

    function getCepData(ev: any) {
        setValidating(true)
        const cep = ev?.replace(/[^0-9]/g, '')
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                    formik.setFieldError('addressDTO.zipCode', 'CEP inválido')
                    setValidating(false)
                } else {
                    setValidating(false)

                    formik.setFieldValue(
                        'addressDTO.zipCode',
                        formatarCEP(formik.values.addressDTO.zipCode)
                    )
                    formik.setFieldValue(
                        'addressDTO.city',
                        `${data.localidade ?? ''}`
                    )
                    formik.setFieldValue('addressDTO.uf', `${data.uf ?? ''}`)
                    formik.setFieldValue(
                        'addressDTO.road',
                        `${data.logradouro ?? ''}`
                    )
                    formik.setFieldValue(
                        'addressDTO.neighborhood',
                        `${data.bairro ?? ''}`
                    )
                    formik.setFieldTouched('addressDTO.zipCode', false)
                }
            })
            .catch((err) => {
                formik.setFieldError('addressDTO.zipCode', 'CEP inválido')
                setValidating(false)
            })
    }



    function togglePassword() {
        setShowPassword(!showPassword)
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const callCreateCollaborator = async (newUser: any) => {
        setIsLoading(true)
        if (validateInfos()) {
            let cleanedCollaboratorUser: any = {
                name: newUser.name,
                rg: removeNonNumeric(newUser.rg) ?? '',
                cpforcnpj: removeNonNumeric(newUser.cpforcnpj) ?? '',
                birthday: convertToBack(newUser.birthday),
                user: {
                    password: newUser.user.password
                },
                sexoID: Number(newUser.sexoID),
                addressDTO: {
                    zipCode: removeNonNumeric(newUser.addressDTO.zipCode) ?? '',
                    uf: newUser.addressDTO.uf,
                    road: newUser.addressDTO.road,
                    number: newUser.addressDTO.number,
                    neighborhood: newUser.addressDTO.neighborhood,
                    city: newUser.addressDTO.city
                },
                contactDTO: {
                    telephone: removeNonNumeric(newUser.contactDTO.telephone),
                    cellPhone:
                        removeNonNumeric(newUser.contactDTO.cellPhone) ?? '',
                    cellPhone2: removeNonNumeric(newUser.contactDTO.cellPhone2),
                    email: removeExtraSpaces(newUser.contactDTO.email)
                }
            }
            CollaboratorService.createCollaborator(cleanedCollaboratorUser)
                .then((resp) => {
                    handleResetStates()
                    navigate(-1)
                })
                .catch((err: AxiosError) => {
                    Notification(String(err.message), 'error')
                    setIsLoading(false)
                })
        }
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.collaboratorRegisterSchema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            await callCreateCollaborator(values)
            setSubmitting(false)
        }
    })

    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
        formik.values.birthday
            ? dayjs(formik.values.birthday, 'DD/MM/YYYY')
            : null
    )

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date)
        formik.setFieldValue('birthday', date ? date.format('DD/MM/YYYY') : '')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <RegisterPage>
                <RegisterPageHeader></RegisterPageHeader>
                <RegisterPageContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sm={6} md={4}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações pessoais
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <GenericTextField<string>
                                            error={!!formik.errors.name}
                                            helperText={formik.errors.name}
                                            small
                                            name={'name'}
                                            label={'Nome completo *'}
                                            value={formik.values.name}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue('name', e)
                                            }
                                            onBlur={() => formik.validateField('name')}
                                        />
                                        <GenericTextField<string>
                                            error={!!formik.errors.cpforcnpj}
                                            helperText={formik.errors.cpforcnpj}
                                            small
                                            name={'cpforcnpj'}
                                            label={'CPF / CNPJ *'}
                                            value={formik.values.cpforcnpj}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'cpforcnpj',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('cpforcnpj')}
                                        />
                                        <GenericTextField<string>
                                            error={!!formik.errors.rg}
                                            helperText={formik.errors.rg}
                                            small
                                            name={'rg'}
                                            label={'RG *'}
                                            value={formik.values.rg}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue('rg', e)
                                            }
                                            onBlur={() => formik.validateField('rg')}
                                        />
                                        <TextField
                                            value={formik.values.sexoID}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'sexoID',
                                                    e.target.value
                                                )
                                            }
                                            disabled={isValidating}
                                            id="outlined-select-state"
                                            margin="none"
                                            select
                                            label="Sexo *"
                                            size="small"
                                            name="sexoID"
                                            error={Boolean(
                                                getIn(formik.errors, 'sexoID')
                                            )}
                                            helperText={getIn(
                                                formik.errors,
                                                'sexoID'
                                            )}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100
                                                        }
                                                    }
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: '1px 10px -5px '
                                                }
                                            }}
                                        >
                                            {sexoOptions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt-br"
                                        >
                                            <FormControl
                                                error={Boolean(
                                                    getIn(
                                                        formik.errors,
                                                        'birthDate'
                                                    )
                                                )}
                                                fullWidth
                                            >
                                                <DateField
                                                    size="small"
                                                    variant="outlined"
                                                    label="Data de nascimento *"
                                                    value={selectedDate}
                                                    format="DD/MM/YYYY"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setDatePickerOpen(true)}
                                                                    edge="end"
                                                                >
                                                                    <CalendarMonthIcon sx={{ color: theme.COLORS.YELLOW2 }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(event) =>
                                                        handleDateChange(event)
                                                    }
                                                    FormHelperTextProps={{
                                                        style: {
                                                            margin: '1px 10px -5px '
                                                        }
                                                    }}

                                                />
                                                <DataPicker
                                                    isOpen={datePickerOpen}
                                                    onClose={() =>
                                                        setDatePickerOpen(false)
                                                    }
                                                    onOpen={() =>
                                                        setDatePickerOpen(true)
                                                    }
                                                    title="Escolher Data de Nascimento"
                                                    initialDate={selectedDate}
                                                    setInitialDate={
                                                        handleDateChange
                                                    }
                                                    typeOfDatePicker="data"
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </InfoCard>
                                </InfoCardContainer>
                            </Grid2>
                            <Grid2 xs={12} sm={6} md={4}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações de endereço
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO?.zipCode
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.zipCode
                                            }
                                            small
                                            name={'addressDTO.zipCode'}
                                            label={'CEP *'}
                                            value={
                                                formik.values.addressDTO.zipCode
                                            }
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.zipCode',
                                                    e
                                                )
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onBlur={() =>
                                                getCepData(
                                                    formik.values.addressDTO
                                                        .zipCode
                                                )
                                            }
                                        />
                                        <TextField
                                            value={formik.values.addressDTO.uf}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'addressDTO.uf',
                                                    e.target.value
                                                )
                                            }
                                            disabled={isValidating}
                                            id="outlined-select-state"
                                            margin="none"
                                            select
                                            label="UF *"
                                            size="small"
                                            name="addressDTO.uf"
                                            error={Boolean(
                                                getIn(
                                                    formik.errors,
                                                    'addressDTO.uf'
                                                )
                                            )}
                                            helperText={getIn(
                                                formik.errors,
                                                'addressDTO.uf'
                                            )}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100
                                                        }
                                                    }
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: '1px 10px -5px '
                                                }
                                            }}
                                        >
                                            {statesOpitions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO?.city
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.city
                                            }
                                            small
                                            name={'addressDTO.city'}
                                            label={'Cidade *'}
                                            value={formik.values.addressDTO.city}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.city',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('addressDTO.city')}
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO
                                                    ?.neighborhood
                                            }
                                            helperText={
                                                formik.errors.addressDTO
                                                    ?.neighborhood
                                            }
                                            small
                                            name={'addressDTO.neighborhood'}
                                            label={'Bairro *'}
                                            value={
                                                formik.values.addressDTO
                                                    .neighborhood
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.neighborhood',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('addressDTO.neighborhood')}
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO?.road
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.road
                                            }
                                            small
                                            name={'addressDTO.road'}
                                            label={'Rua *'}
                                            value={formik.values.addressDTO.road}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.road',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('addressDTO.road')}
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO?.number
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.number
                                            }
                                            small
                                            name={'addressDTO.number'}
                                            label={'Número *'}
                                            value={formik.values.addressDTO.number}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.number',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('addressDTO.number')}
                                        />
                                    </InfoCard>
                                </InfoCardContainer>
                            </Grid2>
                            <Grid2 xs={12} sm={6} md={4}>
                                <InfoCardContainer>
                                    <InfoCardTitle
                                        sx={{ whiteSpace: 'nowrap' }}
                                    >
                                        Informações contato
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.cellPhone
                                            }
                                            helperText={
                                                formik.errors.contactDTO
                                                    ?.cellPhone
                                            }
                                            small
                                            name={'contactDTO.cellPhone'}
                                            label={'Celular principal *'}
                                            value={
                                                formik.values.contactDTO
                                                    .cellPhone
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.cellPhone',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('contactDTO.cellPhone')}
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.cellPhone2
                                            }
                                            helperText={
                                                formik.errors.contactDTO
                                                    ?.cellPhone2
                                            }
                                            small
                                            name={'contactDTO.cellPhone2'}
                                            label={'Celular secundário'}
                                            value={
                                                formik.values.contactDTO
                                                    .cellPhone2!!
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.cellPhone2',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('contactDTO.cellPhone2')}
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.telephone
                                            }
                                            helperText={
                                                formik.errors.contactDTO?.telephone
                                            }
                                            small
                                            name={'contactDTO.telephone'}
                                            label={'Telefone'}
                                            value={
                                                formik.values.contactDTO
                                                    .telephone!!
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.telephone',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('contactDTO.telephone')}
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO?.email
                                            }
                                            helperText={
                                                formik.errors.contactDTO?.email
                                            }
                                            small
                                            name={'contactDTO.email'}
                                            label={'Email'}
                                            value={formik.values.contactDTO.email}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.email',
                                                    e
                                                )
                                            }
                                            onBlur={() => formik.validateField('contactDTO.email')}
                                        />
                                    </InfoCard>
                                    <InfoCardContainer>
                                        <InfoCardTitle>
                                            Senha da conta
                                        </InfoCardTitle>
                                        <InfoCard>
                                            <GenericTextField<string>
                                                error={
                                                    !!formik.errors.user?.password
                                                }
                                                helperText={
                                                    formik.errors.user?.password
                                                }
                                                small
                                                name={'user.password'}
                                                label={'Senha *'}
                                                value={formik.values.user?.password}
                                                onChangeManual={(e: string) =>
                                                    formik.setFieldValue(
                                                        'user.password',
                                                        e
                                                    )
                                                }
                                                onBlur={() => formik.validateField('user.password')}
                                                props={{
                                                    type: showPassword
                                                        ? 'text'
                                                        : 'password',
                                                    onChange: formik.handleChange,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={
                                                                        togglePassword
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityIcon />
                                                                    ) : (
                                                                        <VisibilityOffIcon />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }
                                                }}
                                            />
                                            <GenericTextField<string>
                                                error={
                                                    !!formik.errors.user
                                                        ?.confirmPassword
                                                }
                                                helperText={
                                                    formik.errors.user
                                                        ?.confirmPassword
                                                }
                                                small
                                                name={'user.confirmPassword'}
                                                label={'Confirmar senha *'}
                                                value={
                                                    formik.values.user
                                                        ?.confirmPassword!!
                                                }
                                                onChangeManual={(e: string) =>
                                                    formik.setFieldValue(
                                                        'user.confirmPassword',
                                                        e
                                                    )
                                                }
                                                onBlur={() => formik.validateField('user.confirmPassword')}
                                                props={{
                                                    type: showPassword
                                                        ? 'text'
                                                        : 'password',
                                                    onChange: formik.handleChange,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={
                                                                        togglePassword
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityIcon />
                                                                    ) : (
                                                                        <VisibilityOffIcon />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }
                                                }}
                                            />
                                        </InfoCard>
                                    </InfoCardContainer>
                                </InfoCardContainer>
                            </Grid2>
                        </Grid2>
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Button
                            onClick={() => navigate('/colaboradores')}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={isLoading}
                            onClick={() => formik.handleSubmit()}
                        >
                            Cadastrar
                        </Button>
                    </Box>
                </RegisterPageContent>
            </RegisterPage>
        </LocalizationProvider>
    )
}

export default RegisterCollaborator
