import { TClientBody } from "core/models/clientLBS";
import DefaultModal from "../../defaultModal/defaultModal";
import { Container, InfoValue, PaymentList, PaymentListContainer, PaymentSection } from "../styles";
import { InfoCard, InfoCardContainer, InfoCardTitle, InfoKey, InfoRow, InfosSection, Title } from "app/components/styles";
import { formatCEP, formatCurrencyBR, formatDateBr, formatDocument, formatPhoneNumber, formatRG, formatarCEP, removeNonNumeric } from "core/utils/globalFunctions";
import { Box, Button, CircularProgress, IconButton, Modal, Skeleton, Tooltip, Typography } from "@mui/material";
import { TProductBody } from "core/models/product";
import theme from "core/theme/theme";
import { useEffect, useState } from "react";
import { ProductService } from "core/api/product/productService";
import CaracteristicsInfo from "./caracteristicsInfo";
import { Info } from "@mui/icons-material";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import { SectionCol } from "core/theme/globalStyles";
import { IInfoProps, InfosComponent } from "../ClientDetailsModal";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';


interface TProductDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    product: TProductBody;
}

const ProductDetailsModal = (props: TProductDetailsProps) => {
    const { isOpen, onClose, onOpen, product } = props;
    const [loadingImages, setLoadingImages] = useState(true);
    const [images, setImages] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [rotation, setRotation] = useState(0);
    const handleRotateRight = () => {
        setRotation(prev => prev + 90);
    };

    const handleRotateLeft = () => {
        setRotation(prev => prev - 90);
    };

    const handleResetRotation = () => {
        setRotation(0);
    };

    const handleOpen = (image: any) => {
        setSelectedImage(image);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const productPriceInfos: IInfoProps[] = [
        { key: 'Preço de custo', value: formatCurrencyBR(product.priceCost) },
        { key: 'Preço de etiqueta', value: formatCurrencyBR(product.priceTag) },
        { key: 'Preço de revenda', value: formatCurrencyBR(product.resalePrice) },
        { key: 'Qtd. Total', value: product.amount ?? "--" },
        { key: 'Qtd. Aguardando', value: product.quantityWaiting ?? "--" },
    ]

    const aboutProductInfos: IInfoProps[] = [
        { key: 'Cod. Produto', value: product.sku },
        { key: 'Coleção', value: product.collection.name },
        { key: 'Categoria', value: product.category.name },
        { key: 'Marca', value: product.brand.name },
        { key: 'Fornecedor', value: product.suppiler.nameCompany },
    ]

    const dateInfos: IInfoProps[] = [
        { key: 'Criado em', value: formatDateBr(product.createdAt) },
        { key: 'Atualizado em', value: formatDateBr(product.updatedAt) },
    ]

    // ? carrega as imagens 
    useEffect(() => {
        const fetchImages = async () => {
            if (isOpen) {
                setLoadingImages(true);
                try {
                    const imagePromises = product.images.map(image => ProductService.getImage(image.id));
                    const imageResponses = await Promise.all(imagePromises);
                    const imageData = imageResponses.map(response => `data:image/png;base64,${response}`);
                    setImages(imageData);
                } catch (error) {
                    console.error("Error fetching images", error);
                } finally {
                    setLoadingImages(false);
                }
            }
        };

        fetchImages();
    }, [isOpen, product.images]);

    return (
        <>
            <DefaultModal
                title="Detalhes do produto"
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                children={
                    <Box sx={{ margin: 0, display: "flex", flexDirection: product.productCharacteristics.length > 0 ? "row" : "column", gap: 1, minWidth: "40svw" }}>
                        {product.images.length > 0 &&
                            <Box sx={{
                                display: "flex", flexDirection: product.productCharacteristics.length > 0 ? "column" : "row", flex: 1, minHeight: "190px",
                                gap: 1, padding: "0.4rem", paddingLeft: "1rem",
                                justifyContent: "space-around", alignItems: "center",
                                marginBottom: product.productCharacteristics.length > 0 ? "0rem" : "1rem"
                            }}>
                                {loadingImages ? (
                                    <Box sx={{ width: "160px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    [...Array(3)].map((_, index) => (
                                        index < images.length ? (
                                            <TransformWrapper key={index}>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <Box sx={{
                                                        position: 'relative', border: "2px solid", borderColor: theme.COLORS.YELLOW2, borderRadius: "5px", display: "flex",
                                                        flexDirection: "row", justifyContent: "center", alignItems: "center", overflow: "hidden"
                                                    }}>
                                                        <TransformComponent>
                                                            <img src={images[index]} alt={`Product Image ${index + 1}`} width="160px" height="160px" style={{
                                                                borderRadius: "3px"
                                                            }} />
                                                        </TransformComponent>
                                                        <Box sx={{ position: 'absolute', bottom: 8, display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                            <Tooltip title="Aproximar">
                                                                <IconButton size="small" onClick={() => zoomIn()} sx={{
                                                                    color: theme.COLORS.WHITE, backgroundColor: theme.COLORS.YELLOW,
                                                                    '&:hover': { backgroundColor: theme.COLORS.YELLOW2 }, width: 25, height: 25
                                                                }}>
                                                                    <AddIcon sx={{ width: 25, height: 25 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Afastar">
                                                                <IconButton size="small" onClick={() => zoomOut()} sx={{
                                                                    color: theme.COLORS.WHITE, backgroundColor: theme.COLORS.YELLOW,
                                                                    '&:hover': { backgroundColor: theme.COLORS.YELLOW2 }, width: 25, height: 25
                                                                }}>
                                                                    <RemoveIcon sx={{ width: 25, height: 25 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Expandir">
                                                                <IconButton size="small" onClick={() => handleOpen(images[index])} sx={{
                                                                    color: theme.COLORS.WHITE, backgroundColor: theme.COLORS.YELLOW,
                                                                    '&:hover': { backgroundColor: theme.COLORS.YELLOW2 }, width: 25, height: 25
                                                                }}>
                                                                    <ImageIcon sx={{ width: 20, height: 20 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </TransformWrapper>
                                        ) : (
                                            <Box key={index} sx={{
                                                width: "160px", height: "160px", display: "flex", justifyContent: "center", alignItems: "center",
                                                border: "2px solid", borderColor: theme.COLORS.YELLOW2, borderRadius: "5px", backgroundColor: theme.COLORS.GRAY5
                                            }}>
                                                <Typography sx={{ color: theme.COLORS.WHITE }}>Imagem {index + 1}</Typography>
                                            </Box>
                                        )
                                    ))
                                )}
                                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="image-modal-title"
                aria-describedby="image-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                }}>
                    {selectedImage && (
                        <Box sx={{ position: 'relative' }}>
                            <img
                                src={selectedImage}
                                alt="Expanded Product"
                                width="700px"
                                height="700px"
                                style={{
                                    borderRadius: '8px',
                                    transform: `rotate(${rotation}deg)`,
                                    transition: 'transform 0.3s ease',
                                }}
                            />

                            {/* Botões sobrepostos na imagem */}
                            <Box sx={{
                                position: 'absolute',
                                display: 'flex',
                                gap: 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '3px',
                                bottom: '10px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}>
                                <Tooltip title="Girar para Esquerda">
                                    <IconButton size="small" onClick={handleRotateLeft} sx={{ color: 'white' }}>
                                        <RotateLeftIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Girar para Direita">
                                    <IconButton size="small" onClick={handleRotateRight} sx={{ color: 'white' }}>
                                        <RotateRightIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Restaurar">
                                    <IconButton size="small" onClick={handleResetRotation} sx={{ color: 'white' }}>
                                        <RestoreIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Fechar">
                                    <IconButton size="small" onClick={handleClose} sx={{ color: theme.COLORS.WHITE, backgroundColor: theme.COLORS.RED, borderRadius: 0, padding: "10px" }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Modal>
                            </Box>
                        }
                        <Box sx={{ display: "flex", flexDirection: "column", flex: 4, gap: 4, padding: "0.5rem", }}>
                            <SectionCol sx={{ padding: "0" }}>
                                {product.productCharacteristics.length > 0 ? (
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 3 }}>
                                        <InfosComponent style={{ margin: "0" }}
                                            items={[
                                                {
                                                    title: 'Preços e quantidade',
                                                    infos: productPriceInfos
                                                },
                                                {
                                                    title: 'Sobre o produto',
                                                    infos: aboutProductInfos
                                                },
                                            ]}
                                        />
                                        <InfosComponent style={{ margin: "0" }}
                                            items={[
                                                {
                                                    title: 'Datas',
                                                    infos: dateInfos
                                                }
                                            ]}
                                        />
                                    </Box>

                                ) : (
                                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                        <InfosComponent style={{ margin: "0" }}
                                            items={[
                                                {
                                                    title: 'Preços e quantidade',
                                                    infos: productPriceInfos
                                                },
                                                {
                                                    title: 'Sobre o produto',
                                                    infos: aboutProductInfos
                                                },
                                                {
                                                    title: 'Datas',
                                                    infos: dateInfos
                                                }

                                            ]}
                                        />
                                    </Box>
                                )}
                            </SectionCol>
                            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                <Typography sx={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>
                                    {product.productCharacteristics.length > 0 ? "Caracteristicas" : ""}
                                </Typography>
                                {product.productCharacteristics.length > 0 &&
                                    <CaracteristicsInfo
                                        caracteritics={product.productCharacteristics}
                                    />}
                            </Box>

                        </Box>

                    </Box >
                }
            />
        </>
    )
}

export default ProductDetailsModal