import { RESET_LIST, RESET_PAYMENT_METHOD, RESET_PAYMENT_TO_SALE, SET_CHANGE_VALUE, SET_DISABLED_OPTIONS, SET_FIXED_DISCOUNT, SET_NAME, SET_PERCENTAGE_DISCOUNT, SET_SALE_REQUEST, setPaymentMethodNames } from 'core/redux/slices/saleSlice/saleSlice';
import { RootState } from 'core/redux/store';
import React, { createContext, useCallback, useContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


interface SaleProviderProps {
  children: ReactNode;
}

interface SaleContextProps {
  clearSale: () => void;
  receivedCash: number;
  setReceivedCash: Dispatch<SetStateAction<number>>;
  boletoState: number | undefined;
  setBoletoState: Dispatch<SetStateAction<number | undefined>>;
  pixState: number | undefined;
  setPixState: Dispatch<SetStateAction<number | undefined>>;
  debitoState: number | undefined;
  setDebitoState: Dispatch<SetStateAction<number | undefined>>;
  creditoState: number | undefined;
  setCreditoState: Dispatch<SetStateAction<number | undefined>>;
  moneyState: number | undefined;
  setMoneyState: Dispatch<SetStateAction<number | undefined>>;
  promissoriaState: number | undefined;
  setPromissoriaState: Dispatch<SetStateAction<number | undefined>>;
  consignadoState: number | undefined;
  setConsignadoState: Dispatch<SetStateAction<number | undefined>>;
  fixedDiscountState: number | undefined;
  setFixedDiscountState: Dispatch<SetStateAction<number | undefined>>;
}

const SaleContext = createContext<SaleContextProps | undefined>(undefined);

export const SaleProvider: React.FC<SaleProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { saleRequest,
    autoCompleteNames,
  } = useSelector((state: RootState) => state.sale);

  const [receivedCash, setReceivedCash] = useState<number>(0);
  const [fixedDiscountState, setFixedDiscountState] = useState<number | undefined>(undefined);

  // Guarda os valores dos pagamentos selecionados
  const [boletoState, setBoletoState] = useState<number | undefined>(undefined);
  const [pixState, setPixState] = useState<number | undefined>(undefined);
  const [moneyState, setMoneyState] = useState<number | undefined>(undefined);
  const [promissoriaState, setPromissoriaState] = useState<number | undefined>(undefined);
  const [consignadoState, setConsignadoState] = useState<number | undefined>(undefined);
  const [debitoState, setDebitoState] = useState<number | undefined>(undefined);
  const [creditoState, setCreditoState] = useState<number | undefined>(undefined);

  const clearSale = useCallback(() => {
    dispatch({ type: RESET_LIST });
    dispatch({ type: RESET_PAYMENT_METHOD });
    dispatch({ type: RESET_PAYMENT_TO_SALE });
    dispatch({ type: SET_CHANGE_VALUE, payload: 0 });
    dispatch({ type: SET_PERCENTAGE_DISCOUNT, payload: 0 });
    dispatch({ type: SET_FIXED_DISCOUNT, payload: 0 });
    dispatch({ type: SET_DISABLED_OPTIONS, payload: [] });
    dispatch({
      type: SET_NAME,
      payload: { ...autoCompleteNames, collaboratorName: "", cpfCollaborator: "", clientName: "", clientCpf: "" },
    });
    dispatch({
      type: SET_SALE_REQUEST,
      payload: { ...saleRequest, collaboratorId: 0, clientId: 0 },
    });
    setReceivedCash(0);
    setBoletoState(undefined);
    setPixState(undefined);
    setDebitoState(undefined);
    setCreditoState(undefined);
    setMoneyState(undefined);
    setPromissoriaState(undefined);
    setConsignadoState(undefined);
    setFixedDiscountState(undefined);
    setPaymentMethodNames([]);
  }, [dispatch]);

  return (
    <SaleContext.Provider
      value={{
        clearSale,
        receivedCash,
        setReceivedCash,
        boletoState,
        setBoletoState,
        pixState,
        setPixState,
        debitoState,
        setDebitoState,
        creditoState,
        setCreditoState,
        moneyState,
        setMoneyState,
        promissoriaState,
        setPromissoriaState,
        consignadoState,
        setConsignadoState,
        fixedDiscountState,
        setFixedDiscountState,
      }}
    >
      {children}
    </SaleContext.Provider>
  );
};

export const useSale = (): SaleContextProps => {
  const context = useContext(SaleContext);
  if (!context) {
    throw new Error('useSale must be used within a SaleProvider');
  }
  return context;
};