import {
    TCollaboratorsByMonth,
    TMonthSalesDetails
} from 'core/models/dashboardModel'
import { axiosInstance } from '../axios/axiosInstance'
import { IResponseBody } from 'core/models/utils'
import { DASHBOARD, DASHBOARD_SALES_MONTH } from 'core/utils/constants'

const getCollaboratorSales = async (
    dataInicio: string,
    dataFinal: string
): Promise<TCollaboratorsByMonth[]> => {
    const response = await axiosInstance.get<
        IResponseBody<TCollaboratorsByMonth[]>
    >(DASHBOARD, {
        params: {
            dataInicio: dataInicio,
            dataFinal: dataFinal
        }
    })
    return response.data.data
}

const getMonthSales = async (
    dataInicio: string,
    dataFinal: string
): Promise<TMonthSalesDetails[]> => {
    const response = await axiosInstance.get<IResponseBody<TMonthSalesDetails[]>>(
        DASHBOARD_SALES_MONTH,
        {
            params: {
                dataInicio: dataInicio,
                dataFinal: dataFinal
            }
        }
    )
    return response.data.data
}

export const dashboardService = {
    getCollaboratorSales,
    getMonthSales
}
