import { deepEqual } from 'core/utils/globalFunctions'
import { Validations } from 'core/utils/validations'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import GenericTextField from 'app/components/genericTextField/GenericTextField'
import { TIndicationBody, TIndicationUpdate } from 'core/models/indication'
import { IndicationService } from 'core/api/indication/indicationService'
import DefaultModal from 'app/components/modals/defaultModal/defaultModal'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { IPage } from 'core/models/utils'
import AsyncAutoComplete from 'app/components/assyncronusAutoComplete/AssyncAutoComplete'
import { INDICATION_TYPE_LIST } from 'core/utils/constants'

interface IEditIndicationModalProps {
    isOpen: boolean
    onClose: () => void
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onOpen: () => void
    indication: TIndicationBody
    refetch: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<IPage<TIndicationBody | undefined>, Error>>
}

const EditIndicationModal = (props: IEditIndicationModalProps) => {
    const { isOpen, onClose, onOpen, indication, setIsOpen } = props
    const [isLoading, setIsLoading] = useState(false)
    const [tempIndication, setTempIndication] = useState<TIndicationBody | undefined>(indication)

    const initialValues: TIndicationUpdate = {
        name: indication?.name || '',
        description: indication?.description || '',
        typeIndicationId: indication?.typeIndication.id || 0
    }

    const handleSelectIndication = (selected: any) => {
        formik.setFieldValue('typeIndicationId', selected.id)
        setTempIndication((prevState: any) => ({
            ...prevState,
            typeIndication: {
                ...prevState.typeIndication,
                indication: selected.indication  
            }
        }));
    }

    const handleClearIndication = () => {
        formik.setFieldValue('typeIndicationId', undefined);
        setTempIndication((prevState: any) => ({
            ...prevState,
            typeIndication: {
                ...prevState.typeIndication,
                indication: "" 
            }
        }));
    };
    

    const callEditIndication = async (updatedIndication: TIndicationUpdate) => {
        setIsLoading(true)

        let cleanedIndication: TIndicationUpdate = {
            name: updatedIndication.name,
            description: updatedIndication.description,
            typeIndicationId: updatedIndication.typeIndicationId
        }
        if (indication?.id) {
            IndicationService.updateIndication(
                cleanedIndication,
                String(indication.id)
            )
                .then((resp) => {
                    if (resp.status === 200) {
                        setIsOpen(false)
                        handleResetStates()
                        props.refetch()
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const hasChanges = () => {
        return !deepEqual(formik.values, initialValues)
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.EditIndicationShema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            await callEditIndication(values)
            setSubmitting(false)
        }
    })

    useEffect(() => {
        hasChanges()
    }, [formik.values])

    return (
        <DefaultModal
            title="Editar indicação"
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            children={
                <>
                    <Box
                        sx={{
                            gap: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            padding: '1rem',
                            minWidth: 400
                        }}
                    >
                        <GenericTextField<string>
                            error={!!formik.errors.name}
                            helperText={formik.errors.name}
                            small
                            style={{ width: '100%' }}
                            name={'name'}
                            label={'Nome *'}
                            value={formik.values.name}
                            props={{
                                onChange: formik.handleChange
                            }}
                            onChangeManual={(e: string) =>
                                formik.setFieldValue('name', e)
                            }
                        />
                        <AsyncAutoComplete
                            label={'Tipo de indicação *'}
                            handleOnSelect={handleSelectIndication}
                            urlToSearch={INDICATION_TYPE_LIST}
                            error={formik.errors.typeIndicationId}
                            haveError={!!formik.errors.typeIndicationId}
                            sortField="indication"
                            searchParamName='indication'
                            selectedValue={ { name: `${tempIndication?.typeIndication.indication}` }}
                            onClear={handleClearIndication}
                        />
                        <TextField
                            error={!!formik.errors.description}
                            helperText={formik.errors.description}
                            name="description"
                            label="Descrição *"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: '0.4rem'
                        }}
                    >
                        <Button
                            disabled={isLoading}
                            onClick={() => setIsOpen(false)}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={!hasChanges() || isLoading}
                            onClick={() => formik.handleSubmit()}
                        >
                            Editar
                        </Button>
                    </Box>
                </>
            }
        />
    )
}

export default EditIndicationModal
