import { Box, Button, ButtonGroup, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as uuid from "uuid";
import { useDispatch } from "react-redux";
import { ADD_PRODUCT } from "core/redux/slices/saleSlice/saleSlice";
import theme from "core/theme/theme";
import AsyncAutoComplete from "../assyncronusAutoComplete/AssyncAutoComplete";
import { TListItem } from "app/views/sale/sale";
import { removeNonNumeric } from "core/utils/globalFunctions";
import { PRODUCT_LIST } from "core/utils/constants";
import { useNavigate } from "react-router-dom";
import { TProductRegister } from "core/models/product";
import { CaracteristicsDialog } from "./CaracteristicsModal";
import { useSelector } from "react-redux";
import { RootState } from "core/redux/store";
import { Notification } from "../toastNotification/toastNotification";
import useOnKeyPress from "core/hooks/onKeyPress/onKeyPress";

const initialProduct: TListItem = {
  id: "",
  priceTag: 0,
  resalePrice: 0,
  sku: "",
  productName: "",
  quantity: 0,
  caracteristicsInfos: [],
  caracteristicsRequest: [],
};

const styles = {
  height: 40,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: theme.COLORS.BLACK,
  },
};


function SaleSearch() {
  const { list } = useSelector((state: RootState) => state.sale);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState<any[]>([]);
  const [loadingOptions, setLoadingOptions] = useState(false)

  // Ref para o componente Autocomplete
  const autoCompleteRef = useRef<HTMLElement | null>(null);


  // Estado para controlar o valor selecionado no AsyncAutoComplete
  const [selectedProduct, setSelectedProduct] = useState<TProductRegister | null>(null);
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuid.v4());

  // Item da lista temporário
  const [tempProduct, setTempProduct] = useState<TListItem>(initialProduct);

  // Item da selecionado
  const [quantityProduct, setQuantityProduct] = useState<TListItem>(initialProduct);

  // Dialog para adicionar características
  const [openCaracteristicsDialog, setOpenCaracteristicsDialog] = useState(false);

  const handleClearSearch = () => {
    setSelectedProduct(null);
    setQuantityProduct(initialProduct);
    setTempProduct(initialProduct);
  };

  // Selecionar produto da lista de search
  const handleSelect = (selected: any) => {
    setQuantityProduct(
      {
        id: selected.id,
        image: selected.images,
        priceTag: selected.priceTag,
        sku: selected.sku,
        resalePrice: selected.resalePrice,
        productName: selected.name,
        quantity: selected.amount,
        caracteristicsInfos: selected.productCharacteristics ?? [],
        caracteristicsRequest: [],
      }
    );
    setSelectedProduct(selected);
    if (selected.productCharacteristics.length > 0) {
      setOpenCaracteristicsDialog(true);
    } else {
      setTempProduct({
        id: selected.id,
        image: selected.images,
        priceTag: selected.priceTag,
        sku: selected.sku,
        resalePrice: selected.resalePrice,
        productName: selected.name,
        quantity: 1,
        caracteristicsInfos: selected.productCharacteristics ?? [],
        caracteristicsRequest: [],
      });
    }
  };



  const verifyQuantity = useCallback((productQuantity: TListItem, list: TListItem[], tempProduct: TListItem) => {

    if (tempProduct.caracteristicsInfos.length > 0) {
      let falseCount = 0

      for (let i = 0; i < tempProduct.caracteristicsRequest.length; i++) {
        const tempCharacteristic = tempProduct.caracteristicsRequest[i];
        const productCharacteristic = productQuantity.caracteristicsInfos.find(
          char => char.id === tempCharacteristic.id
        );

        if (productCharacteristic) {
          if (tempProduct.caracteristicsRequest[i].amountProductCharacteristics <= productCharacteristic.amount) {
            falseCount = falseCount;
          }
          else {
            falseCount++;
          }
        }
      }
      if (falseCount > 0) {
        return false;
      }
      else {
        return true;
      }
    }

    // Procura o produto na lista de produtos
    const foundProduct = list.find(item => Number(item.id) === Number(productQuantity.id));

    // Caso não encontre o produto na lista
    if (!foundProduct && tempProduct.caracteristicsInfos.length === 0) {
      return tempProduct.quantity <= productQuantity.quantity;
    }
    else if (foundProduct) {
      const totalQuantity = tempProduct.quantity + foundProduct.quantity;
      return totalQuantity <= productQuantity.quantity;
    }
  }, [quantityProduct]);


  // Adicionar produto na lista
  const handlePushItem = useCallback(() => {
    const verifyCharacteristics = verifyQuantity(quantityProduct, list, tempProduct);
    if (tempProduct && verifyCharacteristics) {
      dispatch({ type: ADD_PRODUCT, payload: tempProduct });
      setSelectedProduct(null);
      setTempProduct(initialProduct);
      setAutoCompleteKey(uuid.v4());

      // Foca o campo autocomplete
      if (autoCompleteRef.current) {
        autoCompleteRef.current.focus();
      }
    }

    else {
      Notification("Produto insuficiente", "error");
    }
  }, [dispatch, tempProduct]);

  // Mudar a quantidade do produto antes de enviar
  const handleChangeQtd = useCallback((newValue: string) => {
    let parsedToNumber = newValue === "" ? 0 : Number(removeNonNumeric(newValue));
    setTempProduct((prev) => prev && { ...prev, quantity: parsedToNumber });
  }, []);

  // Mudar a quantidade do produto em 1
  const handleChangeByOneQtd = useCallback((type: "add" | "minus") => {
    setTempProduct((prev) => prev && {
      ...prev,
      quantity: prev.quantity + (type === "add" ? 1 : prev.quantity === 0 ? 0 : -1),
    });
  }, []);

  // Zerar quantidade
  const handleResetQtd = useCallback(() => {
    setTempProduct((prev) => prev && { ...prev, quantity: 0 });
  }, []);

  const showdNotAdd = useMemo(() => tempProduct.quantity === 0 || !tempProduct.id, [tempProduct]);


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !openCaracteristicsDialog && options.length > 0 && !loadingOptions && tempProduct.quantity === 0) {
        setTimeout(() => {
          handleSelect(options[0]);
        }, 1500);
      } 
      else if (event.key === "Enter" && !openCaracteristicsDialog && tempProduct.quantity > 0 && !loadingOptions) {
          handlePushItem();
      }
    };
  
    // Adiciona o listener de eventos ao montar o componente
    window.addEventListener("keyup", handleKeyPress);
  
    // Remove o listener de eventos ao desmontar o componente
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleSelect, openCaracteristicsDialog, options, tempProduct.quantity, loadingOptions, handlePushItem]);
  


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        gap: "0.5rem",
      }}
    >
      {/* Dialog para adicionar características */}
      <CaracteristicsDialog
        fullProduct={selectedProduct}
        isOpen={openCaracteristicsDialog}
        setOpen={setOpenCaracteristicsDialog}
        setSelectedProduct={setSelectedProduct}
        setTempProduct={setTempProduct}
        handlePushItem={handlePushItem}
        tempProduct={tempProduct}
      />

      {/* Alterar quantidade */}
      <Button sx={styles} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </Button>

      {/* Manipular a quantidade */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <TextField disabled={tempProduct.caracteristicsRequest.length > 0}
          label="Qtd"
          type="number"
          value={tempProduct?.quantity === 0 ? "" : tempProduct?.quantity}
          onChange={(event) => handleChangeQtd(event.target.value)}
          variant="outlined"
          size={"small"}
          sx={{ width: 70 }}
        />
        <ButtonGroup disableElevation variant="outlined">
          <Button disabled={tempProduct.caracteristicsRequest.length > 0} sx={styles} onClick={() => handleChangeByOneQtd("add")}>
            <AddIcon />
          </Button>
          <Button disabled={tempProduct.caracteristicsRequest.length > 0} sx={styles} onClick={() => handleChangeByOneQtd("minus")}>
            <RemoveIcon />
          </Button>
          <Button disabled={tempProduct.caracteristicsRequest.length > 0} sx={styles} onClick={() => handleResetQtd()}>
            Zerar
          </Button>
        </ButtonGroup>
      </Box>

      {/* search */}
      <AsyncAutoComplete<any>
        key={autoCompleteKey}
        inputRef={autoCompleteRef}
        label="Procurar produto"
        handleOnSelect={handleSelect}
        onClear={handleClearSearch}
        urlToSearch={PRODUCT_LIST}
        sortField="createdAt"
        searchParamName="sku"
        selectedValue={selectedProduct}
        setOptionsToKeyDown={setOptions}
        setLoadingOptions={setLoadingOptions}
      />

      {/* botao de adicionar */}
      <Button
        disabled={showdNotAdd}
        onClick={() => handlePushItem()}
        size={"small"}
      >
        Adicionar
      </Button>
    </Box>
  );
}

export default SaleSearch;
