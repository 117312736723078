import {
    FormControl,
    IconButton,
    InputAdornment,
    FormHelperText
} from '@mui/material'
import { DateField } from '@mui/x-date-pickers'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useState } from 'react'
import { Dayjs } from 'dayjs'
import DataPicker from 'app/components/dataPicker/datePicker'
import theme from 'core/theme/theme'
import { getIn } from 'formik'

interface DatePickerFieldProps {
    label: string
    formik: any
    fieldName: string
    selectedDate: Dayjs | null
    setDate: (date: Dayjs | null) => void
}

const DatePickerField = ({
    label,
    formik,
    fieldName,
    selectedDate,
    setDate
}: DatePickerFieldProps) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const handleDateChange = (date: Dayjs | null) => {
        setDate(date)
        formik.setFieldValue(fieldName, date ? date.format('DD/MM/YYYY') : '')
    }

    return (
        <FormControl error={Boolean(getIn(formik.errors, fieldName))} fullWidth>
            <DateField
                size="small"
                variant="outlined"
                label={label}
                value={selectedDate}
                format="DD/MM/YYYY"
                InputProps={{
                    error: Boolean(getIn(formik.errors, fieldName)),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle date picker"
                                onClick={() => setDatePickerOpen(true)}
                                edge="end"
                            >
                                <CalendarMonthIcon
                                    sx={{ color: theme.COLORS.YELLOW2 }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                onChange={handleDateChange}
                FormHelperTextProps={{ style: { margin: '1px 10px -5px ' } }}
            />
            <DataPicker
                isOpen={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
                onOpen={() => setDatePickerOpen(true)}
                title="Escolher Data de Nascimento"
                initialDate={selectedDate}
                setInitialDate={handleDateChange}
                typeOfDatePicker="data"
            />
            {!!getIn(formik.errors, fieldName) && (
                <FormHelperText error sx={{ marginLeft: 1, marginBottom: 0 }}>
                    {getIn(formik.errors, fieldName)}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export default DatePickerField
