import { useQuery } from '@tanstack/react-query'
import { ISelectItem } from 'app/components/filter/defaultFilter'
import Search from 'app/components/search/Search'
import { PageContentContainer } from 'app/components/styles'
import TableHeader from 'app/components/table/tableHeader/TableHeader'
import { ITableHeadCell, Order } from 'core/models/table'
import { fecthSupplier } from 'core/querryes/supplier/supplierQuerry'
import theme from 'core/theme/theme'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'
import DataTable from 'app/components/table/table/table'
import DataTablePagination from 'app/components/table/pagination/pagination'
import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded'
import DefaultMenu, { IMenuItemProps } from 'app/components/menu/DefaultMenu'
import SupplierDetailsModal from 'app/components/modals/detailsModal/SupplierDetailsModal'
import { ContentBody } from '../../styles'
import EditSupplierModal from '../editers/editSupplier'
import { removeNonNumeric } from 'core/utils/globalFunctions'
import NewDefaultFilter from 'app/components/filter/newDefaultFilter'

const head: ITableHeadCell[] = [
    { name: 'cpforcnpj', label: 'CNPJ', align: 'left' },
    {
        name: 'nameRepresentative',
        label: 'Nome do representante',
        align: 'left'
    },
    { name: 'nameCompany', label: 'Nome da compania', align: 'left' },
    { name: 'createdAt', label: 'Data de criação', align: 'left' },
    { name: 'actions2', label: 'Opções', align: 'left' }
]

const items: ISelectItem[] = [
    {
        name: 'Nome do representante',
        value: 'nameRepresentative',
        type: 'texto'
    },
    { name: 'Nome da compania', value: 'nameCompany', type: 'texto' },
    {name: 'CPF / CNPJ', value: 'cpforcnpj', type: 'texto'}
]

function Supplier() {
    const navigate = useNavigate()

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [filterModal, setFilterModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [idSupplier, setIdSupplier] = useState<string | undefined>()
    const [details, setDetails] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const menuItems: IMenuItemProps[] = [
        {
            function: () => {
                setDetails(true)
                handleAccessRowById(idSupplier || '')
                handleCloseMenu()
            },
            label: 'Detalhes do fornecedor'
        },
        {
            function: () => {
                setIsOpen(true)
                handleCloseMenu()
            },
            label: 'Editar fornecedor'
        }
    ]

    const [searchParams, setSearchParams] = useSearchParams()
    const cpforcnpj = searchParams.get('cpforcnpj') || ''
    const nameRepresentative = searchParams.get('nameRepresentative') || ''
    const nameCompany = searchParams.get('nameCompany') || ''

    const { data, isLoading, isSuccess, refetch } = useQuery({
        queryKey: [
            'supplier',
            page,
            rowsPerPage,
            orderBy,
            order,
            nameCompany,
            cpforcnpj,
            nameRepresentative
        ],
        queryFn: () =>
            fecthSupplier(
                page,
                rowsPerPage,
                orderBy,
                order,
                nameRepresentative,
                cpforcnpj,
                nameCompany,
            )
    })

    const handleCpfCnpjChange = (value: string | undefined) => {
        const newParams = new URLSearchParams(searchParams)
        if (value) {
            newParams.set('cpforcnpj', removeNonNumeric(value) ?? '')
        } else {
            newParams.delete('cpforcnpj')
        }
        setSearchParams(newParams)
    }

    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements)
        }
    }, [isSuccess, data])

    const handleAccessRowById = (id: string) => {
        setIdSupplier(id)
    }

    const selectedSupplier = data?.content.find(
        (supplier: any) => supplier.id === idSupplier
    )

    return (
        <PageContentContainer>
            <TableHeader
                filterBtn
                filterBtnAction={() => setFilterModal(true)}
                mainActionFunction={() => navigate('/registrarFornecedor')}
                mainActionLabel="Cadastrar fornecedor"
                mainIcon={<Add sx={{ color: theme.COLORS.YELLOW2 }} />}
                extraComponents={
                    <Search
                        searchPlaceHolder="CNPJ do representante"
                        type="text"
                        querrySearching={isLoading}
                        querry={cpforcnpj}
                        onChange={handleCpfCnpjChange}
                    />
                }
            />
            <ContentBody>
                <DataTable
                    head={head}
                    data={data?.content}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    isLoading={isLoading}
                    menu={
                        <Tooltip title="Opções">
                            <IconButton onClick={handleClickMenu}>
                                <MoreHorizRounded />
                            </IconButton>
                        </Tooltip>
                    }
                    accessRowById={handleAccessRowById}
                />
                <DefaultMenu
                    anchor={anchorEl}
                    menuItems={menuItems}
                    onClose={handleCloseMenu}
                    status={open}
                />
                {selectedSupplier && details && (
                    <SupplierDetailsModal
                        supplier={selectedSupplier}
                        isOpen={details}
                        onClose={() => setDetails(false)}
                        onOpen={() => setDetails(true)}
                    />
                )}
                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
                <NewDefaultFilter
                    isOpen={filterModal}
                    items={items}
                    onClose={() => setFilterModal(false)}
                    onOpen={() => setFilterModal(true)}
                    title="Filtrar Fornecedores"
                    changePage={setPage}
                />
                {selectedSupplier && isOpen && (
                    <EditSupplierModal
                        isOpen={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        setIsOpen={setIsOpen}
                        supplier={selectedSupplier}
                        refetch={refetch}
                    />
                )}
            </ContentBody>
        </PageContentContainer>
    )
}

export default Supplier
