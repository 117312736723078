import { Box, Typography } from '@mui/material'
import styled from 'styled-components'
import theme from './theme'

// ? Modal detalhes
export const Section = styled(Box)`
    display: flex;
    flex-direction: row;
    margin: 1rem;
    gap: 0.5rem;
    
`

export const SectionBloc = styled(Box)`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 2px solid ${theme.COLORS.YELLOW2};
`

export const SectionCol = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    position: relative;
`

export const InfoTitle = styled(Typography)`
    font-size: 16px !important;
    color: #fff !important;
    text-align: center !important;
    font-weight: bold !important;
`

export const InfoRowContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
`

export const InfoKey = styled(Typography)`
    font-size: 16px;
    font-weight: bold !important;
    color: #000;
`

export const InfoValue = styled(Typography)`
    font-size: 15px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
