import { Box, Button, Skeleton } from '@mui/material'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import { IImage } from './productImages'
import theme from 'core/theme/theme'
import { TProductEditImage, TProductRegister } from 'core/models/product'
import { FormikProps } from 'formik'

interface IMiniatureImageProps {
    changeOnFocus: (e: IImage) => void
    src: IImage
    focusImage: IImage
    formik: FormikProps<TProductRegister>
    setInitialImages?: React.Dispatch<React.SetStateAction<TProductEditImage>>
}

function MiniatureImage({
    changeOnFocus,
    src,
    focusImage,
    formik,
    setInitialImages
}: IMiniatureImageProps) {
    // referência para o input file
    const fileInputRef = useRef<HTMLInputElement>(null);

    // monitora constantemente se a miniatura está selecionada
    const isFocused = useMemo(() => focusImage.key === src.key, [focusImage])

    // adicionar imagem ao formik
    const handleOperateImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (src.url === undefined || src.url === "") {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]

                const reader = new FileReader()

                reader.onload = (e) => {
                    // b64 da imagem
                    const image64String = e.target?.result
                        ?.toString()
                        .split(',')[1]
                    // substitui a imagem do formik que está em foco
                    formik.setFieldValue(
                        `productImages.${[src.key]}`,
                        image64String
                    )
                    // altera a imagem que está em foco
                    changeOnFocus({
                        url: image64String,
                        key: src.key,
                        id: src.id
                    })
                    // se mudou a imagem, muda o estado inicial
                    if (setInitialImages) {
                        setInitialImages((prevState) => ({
                            ...prevState,
                            [src.key]: {
                                id: undefined,
                                url: image64String,
                                changed: 'ADICIONADA'
                            }
                        }))
                    }
                }

                reader.readAsDataURL(file)
            }
            changeOnFocus(src)
        }
    }

    // skeleton caso não tenha imagem
    if (!src) {
        return (
            <MiniContainer>
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </MiniContainer>
        )
    }
    return (
        <MiniContainer $onFocus={isFocused} onClick={() => changeOnFocus(src)}>
            {src.url ? (
                <img
                    src={`data:image/jpeg;base64,${src.url}`}
                    alt="Miniature"
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '0.2rem',
                        objectFit: 'cover'
                    }}
                />
            ) : (
              <Button component="label" variant="text">
                    <UploadFileIcon sx={{ margin: 'auto' }} />
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept=".jpeg"
                        onChange={handleOperateImage}
                        ref={fileInputRef}
                    />
                </Button>
            )}
        </MiniContainer>
    )
}

export default MiniatureImage

const MiniContainer = styled(Box)<{ $onFocus?: boolean }>`
    width: 65px;
    height: 65px;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 0.3rem;
    border: ${({ $onFocus }) =>
        $onFocus
            ? `2px solid ${theme.COLORS.YELLOW}`
            : `2px solid ${theme.COLORS.GRAY4}`};
    color: ${({ $onFocus }) =>
        $onFocus ? `${theme.COLORS.YELLOW}` : `${theme.COLORS.GRAY4}`};
    &&:hover {
        border: ${({ $onFocus }) =>
            $onFocus
                ? `2px solid ${theme.COLORS.YELLOW}`
                : `2px solid ${theme.COLORS.GRAY5}`};
        color: ${({ $onFocus }) =>
            $onFocus ? `${theme.COLORS.YELLOW}` : `${theme.COLORS.GRAY5}`};
    }
`
