import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField
} from '@mui/material'
import {
    DateField,
    DatePicker,
    DesktopDatePicker,
    LocalizationProvider
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import GenericTextField from 'app/components/genericTextField/GenericTextField'
import {
    InfoCardContainer,
    InfoCardTitle,
    InfoCard
} from 'app/components/styles'
import { Notification } from 'app/components/toastNotification/toastNotification'
import { AxiosError } from 'axios'
import { ClientLbsService } from 'core/api/client/clientService'
import { TClientRegisterValues } from 'core/models/clientLBS'
import {
    sexoOptions,
    convertToBack,
    formatDate,
    formatarCEP,
    removeExtraSpaces,
    removeNonNumeric,
    statesOpitions,
    maritialStatusOptions,
    formatDocument,
    formatFormikZipcode,
    formatPhoneNumber,
    formatPhoneNumberResidencial
} from 'core/utils/globalFunctions'
import { Validations } from 'core/utils/validations'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { getIn, useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RegisterPage, RegisterPageContent, RegisterPageHeader } from './styles'
import AsyncAutoComplete from 'app/components/assyncronusAutoComplete/AssyncAutoComplete'
import { INDICATION_LIST } from 'core/utils/constants'
import { TIndicationBody } from 'core/models/indication'
import Grid2 from '@mui/material/Unstable_Grid2'
import DataPicker from 'app/components/dataPicker/datePicker'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import theme from 'core/theme/theme'
import CustomField from './customField'
import DatePickerField from './customDateField'

function RegisterClient() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isValidating, setValidating] = useState(false)
    dayjs.locale('pt-br')
    const initialValues: TClientRegisterValues = {
        name: '',
        rg: '',
        cpforcnpj: '',
        birthDate: '',
        maritalStatus: '',
        addressDTO: {
            zipCode: '',
            uf: '',
            road: '',
            number: '',
            neighborhood: '',
            city: ''
        },
        contactDTO: {
            telephone: '',
            cellPhone: '',
            cellPhone2: '',
            email: ''
        },
        indicationId: undefined,
        sexoID: ''
    }

    async function getCepData(ev: any) {
        setValidating(true)
        const cep = ev?.replace(/\D/g, '')
        await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                    formik.setFieldError('addressDTO.zipCode', 'CEP inválido')
                    setValidating(false)
                } else {
                    setValidating(false)

                    formik.setFieldValue(
                        'addressDTO.zipCode',
                        formatarCEP(formik.values.addressDTO.zipCode)
                    )
                    formik.setFieldValue(
                        'addressDTO.city',
                        `${data.localidade ?? ''}`
                    )
                    formik.setFieldValue('addressDTO.uf', `${data.uf ?? ''}`)
                    formik.setFieldValue(
                        'addressDTO.road',
                        `${data.logradouro ?? ''}`
                    )
                    formik.setFieldValue(
                        'addressDTO.neighborhood',
                        `${data.bairro ?? ''}`
                    )
                    formik.setFieldTouched('addressDTO.zipCode', false)
                    formik.setFieldError('addressDTO.zipCode', '')
                }
            })
            .catch((err) => {
                formik.setFieldError('addressDTO.zipCode', 'CEP inválido')
                setValidating(false)
            })
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const handleSelectIndication = (selected: TIndicationBody) => {
        formik.setFieldValue('indicationId', selected.id)
    }

    const callCreateClient = async (newUser: any) => {
        setIsLoading(true)
        let cleanedClientUser: any = {
            name: newUser.name,
            cpforcnpj: removeNonNumeric(newUser.cpforcnpj) ?? '',
            birthDate: convertToBack(newUser.birthDate),
            rg: removeNonNumeric(newUser.rg) ?? '',
            sexoID: Number(newUser.sexoID),
            indicationId: newUser.indicationId,
            maritalStatus: newUser.maritalStatus,
            addressDTO: {
                city: newUser.addressDTO.city,
                neighborhood: newUser.addressDTO.neighborhood,
                number: newUser.addressDTO.number,
                road: newUser.addressDTO.road,
                uf: newUser.addressDTO.uf,
                zipCode: removeNonNumeric(newUser.addressDTO.zipCode) ?? ''
            },
            contactDTO: {
                email: removeExtraSpaces(newUser.contactDTO.email),
                cellPhone2: removeNonNumeric(newUser.contactDTO.cellPhone2),
                cellPhone: removeNonNumeric(newUser.contactDTO.cellPhone) ?? '',
                telephone: removeNonNumeric(newUser.contactDTO.telephone)
            }
        }
        ClientLbsService.createClient(cleanedClientUser)
            .then((resp) => {
                handleResetStates()
                navigate(-1)
            })
            .catch((err: AxiosError) => {
                Notification(String(err.message), 'error')
                setIsLoading(false)
            })
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.clientRegisterSchema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoading(true)
            await callCreateClient(values)
            setSubmitting(false)
        }
    })

    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
        formik.values.birthDate
            ? dayjs(formik.values.birthDate, 'DD/MM/YYYY')
            : null
    )

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date)
        formik.setFieldValue('birthDate', date ? date.format('DD/MM/YYYY') : '')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <RegisterPage>
                <RegisterPageHeader></RegisterPageHeader>
                <RegisterPageContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sm={6} md={6}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações pessoais
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <CustomField
                                            label="Nome completo *"
                                            name="name"
                                            formik={formik}
                                        />
                                        <CustomField
                                            name={'cpforcnpj'}
                                            label={'CPF / CNPJ *'}
                                            formik={formik}
                                            formater={formatDocument}
                                            lenght={18}
                                        />
                                        <CustomField
                                            name={'rg'}
                                            label={'RG *'}
                                            formik={formik}
                                            formater={removeNonNumeric}
                                            lenght={9}
                                        />
                                        <DatePickerField
                                            label="Data de nascimento *"
                                            formik={formik}
                                            fieldName="birthDate"
                                            selectedDate={selectedDate}
                                            setDate={setSelectedDate}
                                        />
                                        <CustomField
                                            label="Sexo"
                                            name="sexoID"
                                            formik={formik}
                                            selectMode={true}
                                            options={sexoOptions}
                                        />
                                        <CustomField
                                            label="Estado civil *"
                                            name="maritalStatus"
                                            formik={formik}
                                            selectMode={true}
                                            options={maritialStatusOptions}
                                        />
                                    </InfoCard>
                                </InfoCardContainer>
                            </Grid2>
                            <Grid2 xs={12} sm={6} md={6}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações de endereço
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <CustomField
                                            label="CEP *"
                                            name="addressDTO.zipCode"
                                            formik={formik}
                                            onBlur={() =>
                                                getCepData(
                                                    formik.values.addressDTO
                                                        .zipCode
                                                )
                                            }
                                        />
                                        <CustomField
                                            name={'addressDTO.uf'}
                                            label={'UF *'}
                                            formik={formik}
                                            selectMode={true}
                                            options={statesOpitions}
                                        />
                                        <CustomField
                                            name={'addressDTO.city'}
                                            label={'Cidade *'}
                                            formik={formik}
                                        />
                                        <CustomField
                                            name={'addressDTO.neighborhood'}
                                            label={'Bairro *'}
                                            formik={formik}
                                        />
                                        <CustomField
                                            name={'addressDTO.road'}
                                            label={'Rua *'}
                                            formik={formik}
                                        />
                                        <CustomField
                                            name={'addressDTO.number'}
                                            label={'Número *'}
                                            formik={formik}
                                        />
                                    </InfoCard>
                                </InfoCardContainer>
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sm={12} md={12}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações contato
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <CustomField
                                            name={'contactDTO.cellPhone'}
                                            label={'Celular principal *'}
                                            formater={formatPhoneNumber}
                                            formik={formik}
                                            lenght={12}
                                        />
                                        <CustomField
                                            name={'contactDTO.cellPhone2'}
                                            label={'Celular secundário'}
                                            formater={formatPhoneNumber}
                                            formik={formik}
                                            lenght={12}
                                        />
                                        <CustomField
                                            name={'contactDTO.telephone'}
                                            label={'Telefone'}
                                            formater={
                                                formatPhoneNumberResidencial
                                            }
                                            formik={formik}
                                            lenght={11}
                                        />
                                        <CustomField
                                            name={'contactDTO.email'}
                                            label={'Email'}
                                            formik={formik}
                                        />
                                    </InfoCard>
                                    <InfoCardContainer>
                                        <InfoCardTitle
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            Indicação
                                        </InfoCardTitle>
                                        <InfoCard>
                                            <AsyncAutoComplete
                                                label="Atribuir indicação"
                                                handleOnSelect={
                                                    handleSelectIndication
                                                }
                                                urlToSearch={INDICATION_LIST}
                                                sortField="name"
                                                error={
                                                    formik.errors.indicationId
                                                }
                                                haveError={
                                                    !!formik.errors.indicationId
                                                }
                                                searchParamName="name"
                                                onClear={() =>
                                                    formik.setFieldValue(
                                                        'indicationId',
                                                        undefined
                                                    )
                                                }
                                            />
                                        </InfoCard>
                                    </InfoCardContainer>
                                </InfoCardContainer>
                            </Grid2>
                        </Grid2>
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Button
                            onClick={() => navigate('/clientes')}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={isLoading}
                            onClick={() => formik.handleSubmit()}
                        >
                            Cadastrar
                        </Button>
                    </Box>
                </RegisterPageContent>
            </RegisterPage>
        </LocalizationProvider>
    )
}

export default RegisterClient
