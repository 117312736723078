import * as yup from 'yup'
import { removeNonNumeric, validarCpfCnpj, validateRG } from './globalFunctions'
import { TCouponRequest } from 'core/models/coupons'
import { TProductRegister } from 'core/models/product'
import { isAfter, isValid, parse } from 'date-fns'

const loginSchema = yup.object({
    login: yup
        .string()
        .required('Digite o usuário')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    password: yup
        .string()
        .min(4, 'Senha deve ter ao menos 4 digitos')
        .required('Digite a senha')
})

const filialSchema = yup.object({
    filial: yup.string().required('Selecione o seu filial')
})

const forgotPasswordSchema = yup.object({
    cpf: yup
        .string()
        .required('Digite o usuário')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    email: yup
        .string()
        .required('Digite o email')
        .email()
})

const redefinePassword = yup.object({
    password: yup
        .string()
        .required('Senha é obrigatória')
        .min(6, 'A senha deve ter no mínimo 6 caracteres'),
    confirmPassword: yup
        .string()
        .required('Confirmação de senha é obrigatória')
        .oneOf([yup.ref('password')], 'As senhas devem ser iguais')
})

const registerCouponSchema = yup.object<TCouponRequest>().shape({
    coupon: yup.string().required('Cupom é obrigatório'),
    description: yup.string().required('Descrição é obrigatório'),
    quatityInstallments: yup
        .string()
        .required('Quantidade de parcelas é obrigatória')
        .test(
            'is-minimum-value',
            'A quantidade mínima de parcelas é 2',
            (value) => parseInt(value || '0') >= 2
        ),
    valuePixCpf: yup.string().required('Descrição é obrigatório'),
    valuePixCnpj: yup.string().required('Descrição é obrigatório'),
    valueInstallmentCpf: yup.string().required('Descrição é obrigatório'),
    valueInstallmentCnpj: yup.string().required('Descrição é obrigatório')
})

const updateSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required()
})

const minDate = new Date(1900, 0, 1)

const clientRegisterSchema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    maritalStatus: yup.string().required('Estado civil é obrigatório'),
    rg: yup
        .string()
        .required('RG é obrigatório'),
    cpforcnpj: yup
        .string()
        .required('CPF ou CNPJ é obrigatório')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    birthDate: yup
        .string()
        .required('Data de Nascimento é obrigatória')
        .test('is-valid-date', 'Data de Nascimento inválida', (value) => {
            if (!value) return false
            const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
            return isValid(parsedDate)
        })
        .test(
            'is-not-future-date',
            'Data de Nascimento não pode ser no futuro',
            (value) => {
                if (!value) return false
                const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
                return parsedDate <= new Date()
            }
        )
        .test('data-inferior', 'Menor data possível é 01/01/1900', (value) => {
            if (!value) return false
            const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
            return isValid(parsedDate) && isAfter(parsedDate, minDate)
        }),
    addressDTO: yup.object().shape({
        zipCode: yup
            .string()
            .required('O CEP é obrigatório')
            .transform((value) => removeNonNumeric(value))
            .matches(/^\d{8}$/, 'CEP inválido'),
        uf: yup.string().required('UF é obrigatório'),
        road: yup.string().required('Rua é obrigatório'),
        number: yup.string().required('Número é obrigatório'),
        neighborhood: yup.string().required('Bairro é obrigatório'),
        city: yup.string().required('Cidade é obrigatória')
    }),
    contactDTO: yup.object().shape({
        email: yup
            .string()
            .email('E-mail inválido')
            .matches(
                /^[^@]+@[^@]+\.[^@]+$/,
                "O e-mail deve conter um '@' seguido de um '.'"
            ),
        cellPhone: yup
            .string()
            .required('Celular principal é obrigatório')
            .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
                if (!value) return true
                if ((removeNonNumeric(value) ?? '').length !== 11) return false
                else return true
            }),
        cellPhone2: yup
            .string()
            .optional()
            .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
                if (!value) return true
                if ((removeNonNumeric(value) ?? '').length !== 11) return false
                else return true
            }),
        telephone: yup
            .string()
            .optional()
            .transform((value) => removeNonNumeric(value))
            .test('telephone-format', 'Necessário ter 10 dígitos', (value) => {
                if (!value) return true
                return value.length === 10
            })
    }),
    indicationId: yup.number().optional(),
    sexoID: yup
        .number()
        .required('Sexo é obrigatório')
        .positive('Escolha uma opção')
})

const collaboratorRegisterSchema = yup.object().shape({
    name: yup
    .string()
    .required('Nome é obrigatório')
    .min(10, 'O nome deve ter no mínimo 10 caracteres'),
    rg: yup
        .string()
        .required('RG é obrigatório'),
    cpforcnpj: yup
        .string()
        .required('CPF ou CNPJ é obrigatório')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    birthday: yup
        .string()
        .required('Data de Nascimento é obrigatória')
        .test('is-valid-date', 'Data de Nascimento inválida', (value) => {
            if (!value) return false
            const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
            return isValid(parsedDate)
        })
        .test(
            'is-not-future-date',
            'Data de Nascimento não pode ser no futuro',
            (value) => {
                if (!value) return false
                const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
                return parsedDate <= new Date()
            }
        ),
    user: yup.object().shape({
        password: yup.string().required('Senha é obrigatória').min(4, 'A senha deve ter no mínimo 6 caracteres'),
        confirmPassword: yup.string().required('Confirmar senha é obrigatório')
    }),
    addressDTO: yup.object().shape({
        zipCode: yup
            .string()
            .required('O CEP é obrigatório')
            .transform((value) => removeNonNumeric(value))
            .matches(/^\d{8}$/, 'CEP inválido'),
        uf: yup.string().required('UF é obrigatório'),
        road: yup.string().required('Rua é obrigatório'),
        number: yup.string().required('Número é obrigatório'),
        neighborhood: yup.string().required('Bairro é obrigatório'),
        city: yup.string().required('Cidade é obrigatória')
    }),
    contactDTO: yup.object().shape({
        email: yup
            .string()
            .email('E-mail inválido')
            .matches(
                /^[^@]+@[^@]+\.[^@]+$/,
                "O e-mail deve conter um '@' seguido de um '.'"
            ),
        cellPhone: yup
            .string()
            .required('Celular principal é obrigatório')
            .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
                if (!value) return true
                if ((removeNonNumeric(value) ?? '').length !== 11) return false
                else return true
            }),
        cellPhone2: yup
            .string()
            .optional()
            .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
                if (!value) return true
                if ((removeNonNumeric(value) ?? '').length !== 11) return false
                else return true
            }),
        telephone: yup
            .string()
            .optional()
            .transform((value) => removeNonNumeric(value))
            .test('telephone-format', 'Necessário ter 10 dígitos', (value) => {
                if (!value) return true
                return value.length === 10
            })
    }),
    sexoID: yup
        .number()
        .required('Sexo é obrigatório')
        .positive('Escolha uma opção')
})

const supplierRegisterSchema = yup.object().shape({
    nameReprensatative: yup
        .string()
        .required('Nome do representante é obrigatório'),
    cpforcnpj: yup
        .string()
        .required('CNPJ é obrigatório')
        .length(14, 'CNPJ inválido')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    nameCompany: yup.string().required('Nome da compania é obrigatória'),
    addressDTO: yup.object().shape({
        zipCode: yup
            .string()
            .required('O CEP é obrigatório')
            .transform((value) => removeNonNumeric(value))
            .matches(/^\d{8}$/, 'CEP inválido'),
        uf: yup.string().required('UF é obrigatório'),
        road: yup.string().required('Rua é obrigatório'),
        number: yup.string().required('Número é obrigatório'),
        neighborhood: yup.string().required('Bairro é obrigatório'),
        city: yup.string().required('Cidade é obrigatória')
    }),
    stateEnrollment: yup
        .string()
        .optional()
        .length(12, 'Inscrição estadual inválida'),
    contactDTO: yup.object().shape({
        cellPhone: yup
            .string()
            .required('Celular principal é obrigatório')
            .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
                if (!value) return true
                if ((removeNonNumeric(value) ?? '').length !== 11) return false
                else return true
            }),
        cellPhone2: yup
            .string()
            .optional()
            .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
                if (!value) return true
                if ((removeNonNumeric(value) ?? '').length !== 11) return false
                else return true
            }),
        telephone: yup
            .string()
            .optional()
            .transform((value) => removeNonNumeric(value))
            .test('telephone-format', 'Necessário ter 10 dígitos', (value) => {
                if (!value) return true
                return value.length === 10
            }),
        email: yup
            .string()
            .required('Email é obrigatório')
            .email('E-mail inválido')
            .matches(
                /^[^@]+@[^@]+\.[^@]+$/,
                "O e-mail deve conter um '@' seguido de um '.'"
            )
    })
})

const BrandRegisterShema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    description: yup.string().required('Descrição é obrigatória')
})

const CategoryRegisterShema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    description: yup.string().required('Descrição é obrigatória')
})

const CollectionRegisterShema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    description: yup.string().required('Descrição é obrigatória')
})

const IndicationRegisterShema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    description: yup.string().required('Descrição é obrigatória'),
    typeIndicationId: yup
        .number()
        .required('Tipo de indicação é obrigatório')
        .positive('Escolha uma opção')
})

const EditIndicationShema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    description: yup.string().required('Descrição é obrigatória'),
    typeIndicationId: yup
        .number()
        .required('Tipo de indicação é obrigatório')
})

const EditCollaboratorShema = yup.object().shape({
    name: yup.string().required('Nome completo é obrigatório'),
    cpforcnpj: yup
        .string()
        .required('CPF / CNPJ é obrigatório')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    cep: yup.string().required('CEP é obrigatório'),
    city: yup.string().required('Cidade é obrigatória'),
    neighborhood: yup.string().required('Bairro é obrigatório'),
    uf: yup.string().required('UF é obrigatório'),
    road: yup.string().required('Rua é obrigatório'),
    number: yup.string().required('Número é obrigatório'),
    email: yup
        .string()
        .email('E-mail inválido')
        .matches(
            /^[^@]+@[^@]+\.[^@]+$/,
            "O e-mail deve conter um '@' seguido de um '.'"
        ),
    cellPhone: yup
        .string()
        .required('Celular principal é obrigatório')
        .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
            if (!value) return true
            if ((removeNonNumeric(value) ?? '').length !== 11) return false
            else return true
        }),
    cellPhone2: yup
        .string()
        .optional()
        .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
            if (!value) return true
            if ((removeNonNumeric(value) ?? '').length !== 11) return false
            else return true
        }),
    telephone: yup
        .string()
        .optional()
        .transform((value) => removeNonNumeric(value))
        .test('telephone-format', 'Necessário ter 10 dígitos', (value) => {
            if (!value) return true
            return value.length === 10
        })
})

const EditClientShema = yup.object().shape({
    name: yup.string().required('Nome completo é obrigatório'),
    cep: yup.string().required('CEP é obrigatório'),
    city: yup.string().required('Cidade é obrigatória'),
    neighborhood: yup.string().required('Bairro é obrigatório'),
    uf: yup.string().required('UF é obrigatório'),
    road: yup.string().required('Rua é obrigatório'),
    number: yup.string().required('Número é obrigatório'),
    rg: yup.string().required('RG é obrigatório'),
    email: yup
        .string()
        .email('E-mail inválido')
        .matches(
            /^[^@]+@[^@]+\.[^@]+$/,
            "O e-mail deve conter um '@' seguido de um '.'"
        ),
    cellPhone: yup
        .string()
        .required('Celular principal é obrigatório')
        .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
            if (!value) return true
            if ((removeNonNumeric(value) ?? '').length !== 11) return false
            else return true
        }),
    cellPhone2: yup
        .string()
        .optional()
        .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
            if (!value) return true
            if ((removeNonNumeric(value) ?? '').length !== 11) return false
            else return true
        }),
    telephone: yup
        .string()
        .optional()
        .transform((value) => removeNonNumeric(value))
        .test('telephone-format', 'Necessário ter 10 dígitos', (value) => {
            if (!value) return true
            return value.length === 10
        }),
    birthdate: yup
        .string()
        .required('Data de Nascimento é obrigatória')
        .test('is-valid-date', 'Data de Nascimento inválida', (value) => {
            if (!value) return false
            const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
            return isValid(parsedDate)
        })
        .test(
            'is-not-future-date',
            'Data de Nascimento não pode ser no futuro',
            (value) => {
                if (!value) return false
                const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
                return parsedDate <= new Date()
            }
        )
        .test('data-inferior', 'Menor data possível é 01/01/1900', (value) => {
            if (!value) return false
            const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
            return isValid(parsedDate) && isAfter(parsedDate, minDate)
        }),
})

const EditSupplierShema = yup.object().shape({
    nameRepresentative: yup
        .string()
        .required('Nome do representante é obrigatório'),
    cpforcnpj: yup
        .string()
        .required('CNPJ é obrigatório')
        .length(14, 'CNPJ inválido')
        .test('cpf-cnpj-validation', 'Documento inválido', validarCpfCnpj),
    nameCompany: yup.string().required('Nome da compania é obrigatória'),
    cep: yup
        .string()
        .required('O CEP é obrigatório')
        .transform((value) => removeNonNumeric(value))
        .matches(/^\d{8}$/, 'CEP inválido'),
    uf: yup.string().required('UF é obrigatório'),
    road: yup.string().required('Rua é obrigatório'),
    number: yup.string().required('Número é obrigatório'),
    // neighborhood: yup.string().required('Bairro é obrigatório'),
    city: yup.string().required('Cidade é obrigatória'),
    stateEnrollment: yup
        .string()
        .optional()
        .length(12, 'Inscrição estadual inválida'),
    cellPhone: yup
        .string()
        .required('Celular principal é obrigatório')
        .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
            if (!value) return true
            if ((removeNonNumeric(value) ?? '').length !== 11) return false
            else return true
        }),
    cellPhone2: yup
        .string()
        .optional()
        .test('cellphone-format', 'Necessário o DDD e o 9', (value) => {
            if (!value) return true
            if ((removeNonNumeric(value) ?? '').length !== 11) return false
            else return true
        }),
    telephone: yup
        .string()
        .optional()
        .transform((value) => removeNonNumeric(value))
        .test('telephone-format', 'Necessário ter 10 dígitos', (value) => {
            if (!value) return true
            return value.length === 10
        }),
    email: yup
        .string()
        .required('Email é obrigatório')
        .email('E-mail inválido')
        .matches(
            /^[^@]+@[^@]+\.[^@]+$/,
            "O e-mail deve conter um '@' seguido de um '.'"
        )
})

const editProductSchema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    sku: yup.string().required('SKU é obrigatório'),
    amount: yup.number().required('Quantidade é obrigatória'),
    active: yup.boolean().required('Status ativo é obrigatório'),
    qrCode: yup.string().required('QR Code é obrigatório'),
    codManual: yup.string().required('Código manual é obrigatório')
})

const ProductRegisterSchema = yup.object<TProductRegister>().shape({
    name: yup.string().required('Nome é obrigatório'),
    sku: yup.string().required('Código é obrigatório'),
    amount: yup.number().required('Quantidade é obrigatória'),
    priceCost: yup.string().required('Preço de custo é obrigatório'),
    priceTag: yup.string().required('Preço de etiqueta é obrigatório'),
    resalePrice: yup.string().required('Preço de revenda é obrigatório'),
    supplierId: yup.number().required('Fornecedor é obrigatório'),
    categoryId: yup.number().required('Categoria é obrigatória'),
    collectionId: yup.number().required('Coleção é obrigatória'),
    brandId: yup.number().required('Marca é obrigatória')
})

export const Validations = {
    loginSchema,
    updateSchema,
    registerCouponSchema,
    filialSchema,

    forgotPasswordSchema,
    redefinePassword,

    clientRegisterSchema,
    collaboratorRegisterSchema,
    supplierRegisterSchema,
    CategoryRegisterShema,
    BrandRegisterShema,
    IndicationRegisterShema,
    CollectionRegisterShema,

    EditCollaboratorShema,
    EditClientShema,
    EditSupplierShema,
    editProductSchema,
    EditIndicationShema,

    ProductRegisterSchema
}
