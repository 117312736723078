import { IMAGE_DELETE, IMAGE_SAVE, IMAGE_UPDATE } from "core/utils/constants"
import { axiosInstance } from "../axios/axiosInstance"
import { Notification } from "app/components/toastNotification/toastNotification"


const updateImage = async (imageBase64: string, idImage: number): Promise<any> => {
    return await axiosInstance
        .put(`${IMAGE_UPDATE}${idImage}`, { imageBase64: imageBase64, typeImage: "PNG" })
        .then((resp) => {
            return resp
        }).catch((error) => {
            Notification(error.response.data.errors, 'error')
            return error
        })
}

const deleteImage = async (idImage: number): Promise<any> => {
    return await axiosInstance.delete(`${IMAGE_DELETE}${idImage}`).then((resp) => {
        return resp
    }).catch((error) => {
        Notification(error.response.data.errors, 'error')
        return error
    })
}

const createImage = async ( imageBase64: string, id: string ): Promise<any> => {
    return await axiosInstance
        .post(`${IMAGE_SAVE}${id}`, { imageBase64: imageBase64, typeImage: "PNG" })
        .then((response) => {
            if (response instanceof Error) {
                return response.data.errors;
            }
            return response;
        });
};

export const ImageService = {
    updateImage,
    deleteImage,
    createImage
}