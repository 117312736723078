import { Box, Button, Skeleton } from "@mui/material";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { IImage } from "./productImages";
import theme from "core/theme/theme";
import { TProductEditImage, TProductRegister } from "core/models/product";
import { Notification } from "../toastNotification/toastNotification";

interface IMainImageProps {
  src: IImage;
  formik: FormikProps<TProductRegister>;
  changeImageOnFocus: (e: IImage) => void;
  setInitialImages?: React.Dispatch<React.SetStateAction<TProductEditImage>>
}

function MainImage({ changeImageOnFocus, src, formik, setInitialImages }: IMainImageProps) {
  // referência para o input file
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Estado para armazenar a rotação da imagem
  const [rotation, setRotation] = useState(0);

  // Função para rotacionar a imagem
  const rotateImage = (direction: "left" | "right") => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      // Ajustar o tamanho do canvas de acordo com a rotação
      if (rotation % 180 !== 0) {
        canvas.width = img.height;
        canvas.height = img.width;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      ctx?.translate(canvas.width / 2, canvas.height / 2);

      const newRotation = direction === "left" ? -90 : 90;
      setRotation((prevRotation) => prevRotation + newRotation);

      ctx?.rotate((newRotation * Math.PI) / 180);
      ctx?.drawImage(img, -img.width / 2, -img.height / 2);

      const rotatedImage64 = canvas.toDataURL("image/jpeg").split(",")[1];

      // Atualiza a imagem no Formik
      formik.setFieldValue(`productImages.${[src.key]}`, rotatedImage64);
      changeImageOnFocus({ url: rotatedImage64, key: src.key, id: src.id });

      // Atualiza o estado inicial, indicando que a imagem foi alterada
      if (setInitialImages) {
        setInitialImages((prevState) => ({
          ...prevState,
          [src.key]: { id: src.id, url: rotatedImage64, changed: "ALTERADA" }
        }));
      }
    };

    img.src = `data:image/jpeg;base64,${src.url}`;
  };

  // adicionar imagem ao formik
  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Verifica o tipo do arquivo
      if (file.type !== "image/jpeg") {
        Notification("Apenas arquivos JPEG são permitidos.", "error");
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        // b64 da imagem
        const image64String = e.target?.result?.toString().split(",")[1];
        // substitui a imagem do formik que está em foco
        formik.setFieldValue(`productImages.${[src.key]}`, image64String);
        // altera a imagem que está em foco
        changeImageOnFocus({ url: image64String, key: src.key, id: src.id });
        // se mudou a imagem, muda o estado inicial
        if (setInitialImages) {
          setInitialImages((prevState) => ({ ...prevState, [src.key]: { id: undefined, url: image64String, changed: "ADICIONADA" } }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // adicionar imagem ao formik
  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Verifica o tipo do arquivo
      if (file.type !== "image/jpeg") {
        Notification("Apenas arquivos JPEG são permitidos.", "error");
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const image64String = e.target?.result?.toString().split(",")[1];
        formik.setFieldValue(`productImages.${[src.key]}`, image64String);
        changeImageOnFocus({ url: image64String, key: src.key, id: src.id });
        if (setInitialImages) {
          setInitialImages((prevState) => ({ ...prevState, [src.key]: { id: src.id, url: image64String, changed: "ALTERADA" } }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // remover a imagem atual
  const handleDeleteImage = () => {
    formik.setFieldValue(`productImages.${[src.key]}`, "");
    changeImageOnFocus({ url: undefined, key: src.key, id: undefined });
    if (setInitialImages) {
      setInitialImages((prevState) => ({ ...prevState, [src.key]: { id: src.id, url: undefined, changed: "EXCLUIDA" } }));
    }
  };

  if (!src) {
    return (
      <SquareContainer>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: "0.2rem" }}
        />
      </SquareContainer>
    );
  }

  return (
    <SquareContainer>
      {!src.url ? (
        <Button component="label" variant="outlined" sx={{ margin: "auto" }}>
          <UploadFileIcon />
          <input
            style={{ display: "none" }}
            type="file"
            accept=".jpeg"
            onChange={handleAddImage}
            ref={fileInputRef}
          />
        </Button>
      ) : (
        <>
          <ImageOptions>
            <RotateLeftIcon sx={{ cursor: "pointer" }} onClick={() => rotateImage("right")} />
            <RotateRightIcon sx={{ cursor: "pointer" }}  onClick={() => rotateImage("left")}/>
            <SwapHorizIcon sx={{ cursor: "pointer" }} onClick={() => fileInputRef.current?.click()} />
            <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => handleDeleteImage()} />
          </ImageOptions>
          <img
            src={`data:image/jpeg;base64,${src.url}`}
            alt="Main"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "0.2rem",
              objectFit: "cover",
            }}
          />
          <input
            type="file"
            accept=".jpeg"
            onChange={handleChangeImage}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </>
      )}
    </SquareContainer>
  );
}

export default MainImage;

const SquareContainer = styled(Box) <{ $onFocus?: boolean }>`
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  border: 2px solid ${theme.COLORS.GRAY4};
`;

const ImageOptions = styled(Box) <{ $onFocus?: boolean }>`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${theme.COLORS.GRAY2};
  opacity: 0.7;
  &&:hover {
    background-color: ${theme.COLORS.GRAY1};
    opacity: 1;
    color: ${theme.COLORS.WHITE};
  }
`;
