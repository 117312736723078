import { IPage, IResponseBody } from "core/models/utils";
import { getFilialAxiosInstance } from "../axios/axiosInstance";
import { FILIAL_LIST } from "core/utils/constants";
import { Notification } from "app/components/toastNotification/toastNotification";


export const getFilial = async (): Promise<any> => {
    const response = await getFilialAxiosInstance.get<IResponseBody<IPage<any>>>(
        FILIAL_LIST,).then((response) => {
            return response.data.data;
        }).catch((err) => {
            Notification(String(err.message), "error");
        });
    return response;
}

