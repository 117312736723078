import { CategoryService } from 'core/api/category/categoryService'
import { TCategoryBody, TCategoryUpdate } from 'core/models/category'
import { deepEqual } from 'core/utils/globalFunctions'
import { Validations } from 'core/utils/validations'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import GenericTextField from 'app/components/genericTextField/GenericTextField'
import DefaultModal from 'app/components/modals/defaultModal/defaultModal'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { IPage } from 'core/models/utils'

interface IEditCategoryModalProps {
    isOpen: boolean
    onClose: () => void
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onOpen: () => void
    category: TCategoryBody
    refetch: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<IPage<TCategoryBody | undefined>, Error>>
}

const EditCategoryModal = (props: IEditCategoryModalProps) => {
    const { isOpen, onClose, onOpen, category, setIsOpen } = props
    const [isLoading, setIsLoading] = useState(false)

    const initialValues: TCategoryUpdate = {
        name: category?.name || '',
        description: category?.description || ''
    }

    const callEditCategory = async (updatedCategory: TCategoryUpdate) => {
        setIsLoading(true)

        let cleanedCategory: TCategoryUpdate = {
            name: updatedCategory.name,
            description: updatedCategory.description
        }
        if (category?.id) {
            CategoryService.updateCategory(cleanedCategory, String(category.id))
                .then((resp) => {
                    if (resp.status === 200) {
                        setIsOpen(false)
                        handleResetStates()
                        props.refetch()
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const hasChanges = () => {
        return !deepEqual(formik.values, initialValues)
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.CategoryRegisterShema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            await callEditCategory(values)
            setSubmitting(false)
        }
    })

    useEffect(() => {
        hasChanges()
    }, [formik.values])

    return (
        <DefaultModal
            title="Editar Categoria"
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            children={
                <>
                    <Box
                        sx={{
                            gap: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            padding: '1rem',
                            minWidth: 400
                        }}
                    >
                        <GenericTextField<string>
                            error={!!formik.errors.name}
                            helperText={formik.errors.name}
                            small
                            style={{ width: '100%' }}
                            name={'name'}
                            label={'Nome *'}
                            value={formik.values.name}
                            props={{
                                onChange: formik.handleChange
                            }}
                            onChangeManual={(e: string) =>
                                formik.setFieldValue('name', e)
                            }
                        />
                        <TextField
                            error={!!formik.errors.description}
                            helperText={formik.errors.description}
                            name="description"
                            label="Descrição *"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: '0.4rem'
                        }}
                    >
                        <Button
                            disabled={isLoading}
                            onClick={() => setIsOpen(false)}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={!hasChanges() || isLoading}
                            onClick={() => formik.handleSubmit()}
                        >
                            Editar
                        </Button>
                    </Box>
                </>
            }
        />
    )
}

export default EditCategoryModal
