import { useState, useMemo, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../core/theme/theme";
import Button from "../Button/button";
import DefaultDialog from "../defaultDialog/defaultDialog";
import { TListItem, TSalePrices } from "app/views/sale/sale";
import { formatCurrencyBR } from "core/utils/globalFunctions";
import { EditCaracteristicQtd } from "./EditQtdCaractRow";
import { SET_LIST, REMOVE_PRODUCT, RESET_LIST, SET_SALE_PRICE_TYPE, SET_NAME, SET_SALE_REQUEST, SET_PAYMENT_METHOD_NAMES, RESET_PAYMENT_TO_SALE, RESET_PAYMENT_METHOD, SET_FIXED_DISCOUNT, SET_PERCENTAGE_DISCOUNT, SET_CHANGE_VALUE, SET_DISABLED_OPTIONS } from "core/redux/slices/saleSlice/saleSlice";
import { RootState } from "core/redux/store";
import { CLIENT_LIST, COLLABORATOR_LIST } from "core/utils/constants";
import AsyncAutoComplete from "../assyncronusAutoComplete/AssyncAutoComplete";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { useSale } from "core/context/SaleInfoContext";

const initialProduct: TListItem = {
  id: "",
  priceTag: 0,
  resalePrice: 0,
  sku: "",
  productName: "",
  quantity: 0,
  caracteristicsInfos: [],
  caracteristicsRequest: [],
};

export const SaleList = () => {
  const dispatch = useDispatch();
  const {
    clearSale
  } = useSale();
  const { list, salePriceType, autoCompleteNames, saleRequest } = useSelector((state: RootState) => state.sale);

  const [tempRow, setTempRow] = useState<TListItem>(initialProduct);
  const [confirmClear, setConfirmClear] = useState(false);
  const [openCaracteristicsDialog, setOpenCaracteristicsDialog] = useState(false);
  const [openQtdDialog, setOpenQtdDialog] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch({ type: SET_SALE_PRICE_TYPE, payload: event.target.value as TSalePrices });
  };

  const handleChangeQtd = () => {
    const newList = list.map((item) => item.id === tempRow.id ? { ...item, quantity: tempRow.quantity } : item);
    dispatch({ type: SET_LIST, payload: newList });
    setOpenQtdDialog(false);
  };

  const handleCallChangeQtd = (row: TListItem) => {
    setTempRow(row);
    if (row.caracteristicsRequest.length === 0) {
      setOpenQtdDialog(true);
    } else {
      setOpenCaracteristicsDialog(true);
    }
  };

  const totalValue = useMemo(
    () =>
      list.reduce(
        (acc, item) =>
          salePriceType === "ATACADO"
            ? acc + (item.resalePrice ?? 0) * item.quantity
            : acc + (item.priceTag ?? 0) * item.quantity,
        0
      ),
    [list, salePriceType]
  );

  const handleSelectClient = (selected: any) => {
    const updatedSaleRequest = { ...saleRequest, clientId: selected.id };
    const updatedNameClient = {
      ...autoCompleteNames,
      clientName: selected.name, cpfClient: selected.cpforcnpj
    };
    dispatch({ type: SET_NAME, payload: updatedNameClient });
    dispatch({ type: SET_SALE_REQUEST, payload: updatedSaleRequest });
  };

  const handleSelectCollaborator = (selected: any) => {
    const updatedSaleRequest = { ...saleRequest, collaboratorId: selected.id };
    const updatedNameCollaborator = {
      ...autoCompleteNames,
      collaboratorName: selected.name, cpfCollaborator: selected.cpforcnpj
    };
    dispatch({ type: SET_NAME, payload: updatedNameCollaborator });
    dispatch({ type: SET_SALE_REQUEST, payload: updatedSaleRequest });
  };

  const onClearClient = () => {
    dispatch({
      type: SET_NAME,
      payload: { ...autoCompleteNames, clientName: "", cpfClient: "" },
    });
    dispatch({
      type: SET_SALE_REQUEST,
      payload: { ...saleRequest, clientId: 0 },
    });
  };

  const onClearCollaborator = () => {
    dispatch({
      type: SET_NAME,
      payload: { ...autoCompleteNames, collaboratorName: "", cpfCollaborator: "" },
    });
    dispatch({
      type: SET_SALE_REQUEST,
      payload: { ...saleRequest, collaboratorId: 0 },
    });
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && openQtdDialog) {
        handleChangeQtd();
      }
    };
    // Adiciona o listener de eventos ao montar o componente
    window.addEventListener("keyup", handleKeyPress);

    // Remove o listener de eventos ao desmontar o componente
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleChangeQtd, openQtdDialog]);



  return (
    <Box
      sx={{
        flex: 12,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: theme.COLORS.WHITE,
        border: `2px solid ${theme.COLORS.GRAY5}`,
        borderRadius: "0.5rem",
        overflow: "hidden",
      }}
    >
      <TableContainer component={Paper} sx={{ flex: 10, overflowY: "scroll" }}>
        <Table sx={{ minWidth: 650, height: list.length === 0 ? "100%" : undefined }}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Cod. produto</TableCell>
              <TableCell>Quantidade</TableCell>
              <TableCell>Preço unidade</TableCell>
              <TableCell>Preço total</TableCell>
              <TableCell>Carácteristicas</TableCell>
              <TableCell>Remover</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                  <RemoveShoppingCartIcon sx={{ color: theme.COLORS.GRAY5, width: 180, height: 180 }} />
                </TableCell>
              </TableRow>
            ) : (
              list.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.productName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.sku}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <IconButton sx={{ width: 30, height: 30 }} onClick={() => handleCallChangeQtd(row)}>
                      <Typography>
                        {row.caracteristicsRequest.length > 0
                          ? row.caracteristicsRequest.reduce((acc, qtd) => acc + qtd.amountProductCharacteristics, 0)
                          : row.quantity}
                      </Typography>
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatCurrencyBR(salePriceType === "ATACADO" ? row.resalePrice ?? 0 : row.priceTag ?? 0)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatCurrencyBR(
                      (row.caracteristicsRequest.length > 0
                        ? row.caracteristicsRequest.reduce((acc, qtd) => acc + qtd.amountProductCharacteristics, 0)
                        : row.quantity) * (salePriceType === "ATACADO" ? row.resalePrice ?? 0 : row.priceTag ?? 0)
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.caracteristicsRequest.length === 0
                      ? "Não possui"
                      : row.caracteristicsInfos.map((item) => {
                        return row.caracteristicsRequest.map((item2) => {
                          if (item.id === item2.id) {
                            return (
                              <Typography key={item.id}>
                                {item.characteristics.name}: {item.description}
                              </Typography>
                            );
                          }
                          return null;
                        });
                      })}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <IconButton sx={{ cursor: "pointer" }} onClick={() => dispatch({ type: REMOVE_PRODUCT, payload: row.id })}>
                      <DeleteIcon sx={{ color: theme.COLORS.RED }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
          <FormControl>
            <InputLabel>Tipo Venda</InputLabel>
            <Select value={salePriceType} label="Tipo Venda" onChange={handleChange} defaultValue="REVENDA">
              <MenuItem value={"ATACADO"}>Atacado</MenuItem>
              <MenuItem value={"VAREJO"}>Varejo</MenuItem>
            </Select>
          </FormControl>
          <Button
            sx={{ width: 120, height: 50 }}
            title={"Limpar"}
            isLoading={false}
            disabled={list.length === 0}
            onClick={() => setConfirmClear(true)}
            sizeSpinner={20}
          />
          <Typography>Total: {formatCurrencyBR(totalValue)}</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "25%", alignItems: "center", padding: "0.5rem", border: `1px solid ${theme.COLORS.YELLOW2}`, borderRadius: "5px" }}>
          <AsyncAutoComplete
            selectedValue={{ name: `${autoCompleteNames.clientName}` }}
            label="Procurar cliente"
            handleOnSelect={handleSelectClient}
            urlToSearch={CLIENT_LIST}
            sortField="createdAt"
            onClear={onClearClient}
            searchParamName="name"
            active="active"
          />
          <AsyncAutoComplete
            selectedValue={{ name: `${autoCompleteNames.collaboratorName}` }}
            label="Procurar vendedor"
            handleOnSelect={handleSelectCollaborator}
            urlToSearch={COLLABORATOR_LIST}
            sortField="createdAt"
            onClear={onClearCollaborator}
            searchParamName="name"
          />
        </Box>
      </Box>

      <EditCaracteristicQtd
        isOpen={openCaracteristicsDialog}
        setOpen={setOpenCaracteristicsDialog}
        setTempProduct={setTempRow}
        tempProduct={tempRow}
        setList={(newList) => dispatch({ type: SET_LIST, payload: newList })}
      />
      <DefaultDialog
        title="Alterar quantidade"
        confirmAction={handleChangeQtd}
        isOpen={openQtdDialog}
        onCloseAction={() => setOpenQtdDialog(false)}
        disabled={!tempRow.quantity || tempRow.quantity === 0}
        body={
          <Box>
            <Typography>Quantidade</Typography>
            <TextField
              type="number"
              value={tempRow.quantity}
              onChange={(e) => setTempRow((prev) => ({ ...prev, quantity: parseInt(e.target.value) }))}
            />
          </Box>
        }
      />
      <DefaultDialog
        title="Limpar a lista?"
        confirmAction={() => {
          clearSale();
          setConfirmClear(false);
        }}
        isOpen={confirmClear}
        onCloseAction={() => setConfirmClear(false)}
      />
    </Box>
  );
};
