import {
    Box,
    Button,
    Icon,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import DeleteIcon from '@mui/icons-material/Delete'

import {
    ActionLeftSection,
    ActionRightSection,
    ActionSection,
    Container,
    FilterSection
} from './styles'
import DefaultChipList from 'app/components/chip/defaultChipList'
import theme from 'core/theme/theme'
import { SwitchProductList } from 'app/components/switch/switch'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

interface TableHeaderProps {
    mainIcon?: any
    status?: boolean
    filterBtn?: boolean
    extraComponents?: any
    mainActionLabel?: string
    mainActionDisabled?: boolean
    secondaryActionLabel?: string
    filterBtnAction?: () => void
    mainActionFunction?: () => void
    switchActionFunction?: () => void
    secondaryActionFunction?: () => void
}

function TableHeader({
    mainActionFunction,
    mainActionLabel,
    extraComponents,
    filterBtn,
    filterBtnAction,
    secondaryActionFunction,
    secondaryActionLabel,
    mainActionDisabled,
    mainIcon,
    switchActionFunction,
    status
}: Readonly<TableHeaderProps>) {
    const [searchParams, setSearchParams] = useSearchParams()

    const hasFiltersApplied = useMemo(() => {
        return Array.from(searchParams.keys()).some((param) => param !== '')
    }, [searchParams])

    const clearFilters = () => {
        setSearchParams({})
    }

    return (
        <Container>
            <ActionSection>
                <ActionLeftSection>
                    {filterBtn && filterBtnAction && (
                        <Tooltip title="Filtros">
                            <IconButton
                                sx={{ width: 30, height: 30 }}
                                onClick={() => filterBtnAction()}
                            >
                                <FilterListIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: theme.COLORS.YELLOW2
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Remover filtros">
                        <IconButton
                            sx={{ width: 35, height: 35 }}
                            onClick={clearFilters}
                        >
                            <DeleteIcon
                                sx={{
                                    fontSize: '20px',
                                    color: !hasFiltersApplied
                                        ? theme.COLORS.GRAY5
                                        : theme.COLORS.RED
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    {extraComponents}
                    {switchActionFunction && (
                        <SwitchProductList change={switchActionFunction} />
                    )}
                    {status && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 3,
                                alignItems: 'center',
                                width: '400px',
                                marginBottom: '3px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: theme.COLORS.GREEN4
                                    }}
                                />
                                <Typography>Em estoque</Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: theme.COLORS.YELLOW
                                    }}
                                />
                                <Typography>Baixo estoque</Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: theme.COLORS.RED
                                    }}
                                />
                                <Typography>Em falta</Typography>
                            </Box>
                        </Box>
                    )}
                </ActionLeftSection>
                <ActionRightSection>
                    {secondaryActionLabel && secondaryActionFunction && (
                        <Button onClick={() => secondaryActionFunction()}>
                            {secondaryActionLabel}
                        </Button>
                    )}
                    {mainActionLabel && mainActionFunction && (
                        <IconButton
                            sx={{
                                display: 'flex',
                                gap: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    borderRadius: 1
                                }
                            }}
                            onClick={() => mainActionFunction()}
                            disabled={mainActionDisabled}
                            edge="start"
                        >
                            {mainIcon}
                            <Typography color={theme.COLORS.YELLOW2}>
                                {mainActionLabel}
                            </Typography>
                        </IconButton>
                    )}
                </ActionRightSection>
            </ActionSection>

            <FilterSection>
                <DefaultChipList />
            </FilterSection>
        </Container>
    )
}

export default TableHeader
