import { Navigate, Outlet } from "react-router-dom";

const ProtectLogin = () => {

  if (localStorage.getItem("filial") === null) {
    return <Navigate replace to={"/selecionarFilial"} />;
  }

  return <Outlet />;
};

export default ProtectLogin;