import { Box, Chip, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

function DefaultChipList() {
    const [searchParams, setSearchParams] = useSearchParams()

    // Função para remover um filtro específico da URL
    const removeFilter = (key: string) => {
        const newParams = new URLSearchParams(searchParams)
        newParams.delete(key)
        setSearchParams(newParams)
    }

    return (
        <Box>
            {Array.from(searchParams.entries()).map(
                ([key, value]) =>
                    value !== undefined &&
                    value !== '' && (
                        <Chip
                            key={key}
                            label={<ChipContent label={key} value={value} />}
                            onDelete={() => removeFilter(key)}
                            style={{
                                margin: '0.2rem',
                                borderRadius: 5,
                                cursor: 'pointer'
                            }}
                            sx={{
                                ':hover': {
                                    background: '#fff',
                                    border: '2px solid',
                                    borderColor: (props) =>
                                        props.palette.primary.main
                                }
                            }}
                        />
                    )
            )}
        </Box>
    )
}

export default DefaultChipList

interface IContentProps {
    label: string
    type?: string
    value: unknown
}

const ChipContent = (props: IContentProps) => {
    function returnLabelByKey(key: string): string {
        switch (key) {
            case 'nameCompany':
                return 'Nome da compania'
            case 'nameRepresentative':
                return 'Nome do representante'
            case 'type':
                return 'Tipo'
            case 'name':
                return 'Nome'
            case 'coupon':
                return 'Cupom'
            case 'cpforcnpj':
                return 'CPF/CNPJ'
            case 'clientcpforcnpj':
                return 'Cliente CPF/CNPJ'
            case 'status':
                return 'Status'
            case 'cameThrough':
                return 'Veio por'
            case 'typePayment':
                return 'T. pagamento'
            case 'sellerCpfOrCnpj':
                return 'Doc. vendedor'
            case 'clientCpforCnpj':
                return 'Doc. cliente'
            case 'indicationCpforCnpj':
                return 'Doc. indicação'
            case 'createDate':
                return 'Criado em'
            case 'sellerId':
                return 'Id vendedor'
            case 'salesCode':
                return 'Codigo da venda'
            case 'active':
                return 'Ativo'
            case 'statusProduct':
                return 'Status do produto'
            default:
                return key
        }
    }

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography style={{ fontWeight: 'bold' }}>
                {returnLabelByKey(props.label)}:
            </Typography>
            {props.label === 'statusProduct' ? (
                <Typography>{props.value === 'AVAILABLE' ? 'Em estoque' : props.value === 'LOW_STOCK' ? "Acabando" : 'Faltando'}</Typography>
            ) : (
                <Typography>{props.value as string}</Typography>
            )}
        </Box>
    )
}
